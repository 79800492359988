import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loadingElement: HTMLIonLoadingElement;

  constructor(
    private loadingController: LoadingController
  ) { }

  async presentLoading() {
    this.loadingElement = await this.loadingController.create({
      message: `Please wait...`,
      duration: 60000
    });
    await this.loadingElement.present();
  }

  async dismissLoading() {
    if (this.loadingElement) {
      await this.loadingElement.dismiss();
    }
  }
}
