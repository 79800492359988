import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { Location } from '@angular/common';
import { debugLog } from './core/util';

/**
 * 개별 페이지를 표시하기 전에 준비해야 할 것이 있으면 기다리도록 한다.
 * - unifiedMenu
 */
@Injectable({
  providedIn: 'root'
})
export class MenuGuardService implements CanActivate {
  constructor(
    private router: Router,
    private location: Location
  ) { }

  promiseForInit(url: string) {
    return new Promise<boolean>((resolve) => {
      const state: any = this.location.getState();
      const food = state ? { ...state.food } : undefined;

      if (food) {
        resolve(true);
      } else {
        this.router.navigate([ url ]);
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const url = `${route.paramMap.get('site')}/${route.paramMap.get('shop')}`;
    return this.promiseForInit(url);
  }
}

