import { Injectable } from '@angular/core';
import { UserState } from '../../core/schema';

const prefix = 'toeAppFinger';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  storage: Storage;
  userState: UserState = {};

  constructor() {
    this.storage = window.localStorage;
  }

  checkAvailable() {
    try {
      const x = '__storage_test__';
      this.storage.setItem(x, x);
      this.storage.removeItem(x);

      return true;
    } catch (error) {
      return error instanceof DOMException && (
        // everything except Firefox
        error.code === 22 ||
        // Firefox
        error.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        error.name === 'QuotaExceededError' ||
        // Firefox
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        (this.storage && this.storage.length !== 0);
    }
  }

  isExist(key: string) {
    if (this.storage.getItem(`${prefix}${key}`)
      && this.storage.getItem(`${prefix}${key}`).length > 0) {
      this.getValue(key);
      return (this.userState[key] !== null);
    }

    return false;
  }

  getValue(key: string) {
    const value = this.storage.getItem(`${prefix}${key}`);
    this.userState[key] = JSON.parse(value);

    return this.userState[key];
  }

  setItem(key: string, value: string | object) {
    try {
      this.storage.setItem(`${prefix}${key}`, JSON.stringify(value));
    } catch (error) {
      return error;
    }
  }

  clearStorage() {
    this.storage.clear();
    this.userState = undefined;
  }
}
