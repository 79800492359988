import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentSiteService {
  currentSite: string;
  latestCurrentSiteSubject = new BehaviorSubject<string>('');

  observe(): Observable<string> {
    return this.latestCurrentSiteSubject.asObservable();
  }

  setCurrentSite(site: string) {
    this.currentSite = site;
    this.latestCurrentSiteSubject.next(this.currentSite);
  }
}
