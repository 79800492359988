import cloneDeep from 'lodash/cloneDeep';

import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { UnifiedMenuDoc, UnifiedMenuFoodGrp } from './schema';
import { BehaviorSubject, Subscription } from 'rxjs';
// import { UtilService } from './util.service';
// import { environment } from '../../environments/environment';
// import { debugLog } from './util';
// import { map } from 'rxjs/operators';

const collectionPath = 'unifiedMenu';

@Injectable({
  providedIn: 'root'
})
export class UnifiedMenuService {
  currentOrganization: string;
  currentShop: string;

  unifiedMenuForOrganization: UnifiedMenuDoc[];
  latestUnifiedMenuForOrganizationSubject = new BehaviorSubject<UnifiedMenuDoc[]>([]);
  unifiedMenuForOrganizationSubscription: Subscription;

  unifiedMenuForShop: UnifiedMenuDoc;
  latestUnifiedMenuForShopSubject = new BehaviorSubject<UnifiedMenuDoc>(null);
  unifiedMenuForShopSubscription: Subscription;

  constructor(
    private db: AngularFirestore,
    // private utilService: UtilService
  ) { }

  public restartObservingForOrganization(organization: string) {
    if (this.currentOrganization === organization) {
      return;
    }

    this.unifiedMenuForOrganization = undefined;

    if (this.unifiedMenuForOrganizationSubscription) {
      this.unifiedMenuForOrganizationSubscription.unsubscribe();
      this.unifiedMenuForOrganizationSubscription = null;
    }

    this.currentOrganization = organization;
    this.observeMenu('organization', organization);
  }

  public restartObservingForShop(shopNo: string, forceRestart: boolean = false) {
    if (this.currentShop === shopNo && !forceRestart) {
      return;
    }

    this.unifiedMenuForShop = undefined;

    if (this.unifiedMenuForShopSubscription) {
      this.unifiedMenuForShopSubscription.unsubscribe();
      this.unifiedMenuForShopSubscription = null;
    }

    this.currentShop = shopNo;
    this.observeMenu('shopNo', shopNo);
  }

  /**
   * 주어진 key/value 조건에 맞는 메뉴 목록을 가져온다.
   *
   * @param key 'organization' | 'site' | 'room' | 'shopNo'
   */
  private observeMenu(key: 'organization' | 'site' | 'room' | 'shopNo', value: string) {
    // debugLog(`${this.constructor.name}::observeMenu for ${key}:${value}`);
    const queryFn: QueryFn = ref => {
      const query = ref.where(key, '==', value);
      return query;
    };

    const collection = this.db.collection<UnifiedMenuDoc>(collectionPath, queryFn);

    // 디버깅용
    // if (environment.production === false) {
    //   collection.stateChanges().pipe(
    //     map(actions => actions.map(action => {
    //       return { _type: action.type, ...action.payload.doc.data() };
    //     }))
    //   ).subscribe(menus => {
    //     for (const menu of menus) {
    //       // debugLog(`[${menu._id}] ${menu.room}/${menu.shopName}`);
    //     }
    //   });
    // }

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    if (key === 'organization') {
      this.unifiedMenuForOrganizationSubscription = collection.valueChanges().subscribe(doc => {
        this.unifiedMenuForOrganization = this.replaceBaeminWithKTCloud(doc);
        this.latestUnifiedMenuForOrganizationSubject.next(this.unifiedMenuForOrganization);
      });
    } else if (key === 'shopNo') {
      this.unifiedMenuForShopSubscription = collection.valueChanges().subscribe(doc => {
        this.unifiedMenuForShop = this.replaceBaeminWithKTCloud(doc)[0];
        this.latestUnifiedMenuForShopSubject.next(this.unifiedMenuForShop);
      });
    }
  }

  // 이미지 URL의 배민 도메인을 KT클라우드 서비스의 도메인으로 변경한다.
  replaceBaeminWithKTCloud(unifiedMenuDoc: UnifiedMenuDoc[]) {
    const ktStaticPrefix = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/toe-menu-images';
    const result: UnifiedMenuDoc[] = cloneDeep(unifiedMenuDoc);

    for (const prop of Object.keys(result)) {
      const menus: UnifiedMenuFoodGrp[] = result[prop].menus;
      menus.forEach(menu => {
        menu.foods.forEach(food => {
          if (food.imageUrl) {
            const imageUrl = food.imageUrl.split('.com');
            imageUrl[0] = ktStaticPrefix;
            food.imageUrl = imageUrl.join('');
          }
        });
      });
      result[prop].menus = menus;
    }

    return result;
  }
}
