import { Component, OnInit, Input } from '@angular/core';
import { UnifiedOrderMerge } from '../../core/schema';
import { ConfService } from '../../core/conf.service';
import { TranslationService } from '../../core/translation.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss', './menu.component.print.scss']
})
export class MenuComponent implements OnInit {
  @Input() isPrint = false;
  @Input() order: UnifiedOrderMerge;
  @Input() showPrice = true; // 주방용에서는 제외한다.

  constructor(
    private confService: ConfService,
    private translationService: TranslationService
  ) { }

  ngOnInit() {
  }

  postProcessFoodName(foodName: string) {
    // (사이드)잡채_사이드 같은 경우는 _사이드를 제거한다.
    const neatName = foodName.replace(/(\(사이드\).+)_사이드$/, '$1');

    if (this.confService.translateForRoom(this.order.organization, this.order.site, this.order.room)) {
      const translationForRoom = this.translationService.translation[this.order.room];
      if (translationForRoom === undefined) {
        console.error(`No translation for ${this.order.room}`);
      } else {
        const translatedName = translationForRoom.menuTranslation[foodName];

        if (translatedName === '') {
          return neatName;
        } else if (translatedName) {
          return `${neatName} | ${translatedName}`;
        } else {
          console.error(`No translated name for ${foodName}`);
        }
      }
    }

    return neatName;
  }

  // normalizeFoodOpts(food: Food) {
  //   let foodOpts = food.foodName;

  //   if (food.foodOpts[0].optName !== '') {
  //     foodOpts += `_${food.foodOpts[0].optName}`;
  //   }
  //   foodOpts = `<span class="copyable" (click)="copyToClipboard($event)">${foodOpts}</span>`;

  //   if (food.foodOpts.length > 1) {
  //     foodOpts += '<br/>' + food.foodOpts.slice(1).map(opt => `+ ${opt.optName}`).join('<br/>');
  //   }

  //   return foodOpts;
  // }
}
