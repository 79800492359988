import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material';

@Component({
  selector: 'app-dialog-spinner',
  templateUrl: './dialog-spinner.component.html',
  styleUrls: ['./dialog-spinner.component.scss']
})
export class DialogSpinnerComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogSpinnerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onOk(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = undefined;
    }
  }

  ngOnInit() {
  }
}
