import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyMaterialModule } from '../my-material/my-material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SafePipe } from './safe.pipe';

import { MenuComponent } from './menu/menu.component';

import { DialogNoticeComponent } from './dialog-notice/dialog-notice.component';
import { DialogSpinnerComponent } from './dialog-spinner/dialog-spinner.component';
import { DialogLoginComponent } from './dialog-login/dialog-login.component';
import { IonicModule } from '@ionic/angular';
import { AddressSearchModalComponent } from './modal/address-search-modal.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    MyMaterialModule
  ],
  declarations: [
    SafePipe,
    MenuComponent,
    DialogNoticeComponent,
    DialogSpinnerComponent,
    DialogLoginComponent,
    AddressSearchModalComponent
  ],
  exports: [
    SafePipe,
  ],
  entryComponents: [
    DialogNoticeComponent,
    DialogSpinnerComponent,
    DialogLoginComponent,
    AddressSearchModalComponent
  ]
})
export class SharedModule { }
