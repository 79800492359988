import { Injectable } from '@angular/core';

//
// ref: https://developers.google.com/gtagjs/reference/api
//

// index.html의 config 값과 일치해야 한다.
const trackID = 'UA-147991513-2';

@Injectable({
  providedIn: 'root'
})
export class GtagService {
  constructor() { }

  /**
   * URL이 변경되면 호출해 준다.
   * SPA라서 필요하다.
   *
   */
  page(url: string) {
    (window as any).gtag('config', trackID, { page_path: url });
  }

  /**
   * ref: https://stackoverflow.com/questions/50231721/how-to-track-utm-source-in-google-analytics-using-gtag
   */
  campaign(medium: string, source: string, name?: string) {
    if (name) {
      (window as any).gtag('config', trackID, {
        campaign: {
          medium,
          source,
          name
        }
      });
    } else {
      (window as any).gtag('config', trackID, {
        campaign: {
          medium,
          source
        }
      });
    }
  }
}
