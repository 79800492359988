import cloneDeep from 'lodash/cloneDeep';

import { Subscription, combineLatest } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { UnifiedMenuService } from '../../core/unified-menu.service';
import { UnifiedMenuDoc, UnifiedMenu, OrganizationSite, OrganizationRoom } from '../../core/schema';
import { ShoppingCartService } from '../../core/shopping-cart.service';
import { CurrentSiteService } from 'src/app/core/current-site.service';
import { ConfService } from 'src/app/core/conf.service';

interface UnifiedMenuForSite {
  site: string;
  unifiedMenu: UnifiedMenu[];
}
@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit, OnDestroy {
  sites: { [site: string]: OrganizationSite };
  currentSite: string;
  unifiedMenuForSite: UnifiedMenuForSite[] = [];

  placeholderForShop = [];
  isloading = true;
  productsInCart = 0;

  private shoppingCartSubscription: Subscription = null;
  private currentSiteSubscription: Subscription = null;

  private combineLatestSubscription: Subscription = null;

  constructor(
    private unifiedMenuService: UnifiedMenuService,
    private shoppingCartService: ShoppingCartService,
    private confService: ConfService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private currentSiteService: CurrentSiteService
  ) { }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    if (this.combineLatestSubscription) {
      this.combineLatestSubscription.unsubscribe();
    }
    if (this.shoppingCartSubscription) {
      this.shoppingCartSubscription.unsubscribe();
    }
    if (this.currentSiteSubscription) {
      this.currentSiteSubscription.unsubscribe();
    }
  }

  initialize() {
    this.isloading = true;
    this.observe();
    this.observeCurrentSite();
    this.syncUrlForSite();

    this.setPlaceHolder();
    this.observeShoppingCart();
  }

  /******************************************************************
   * [initialize & observing]
   ******************************************************************/
  observe() {
    const organization = 'ghostkitchen';
    const unifiedMenu = this.unifiedMenuService.latestUnifiedMenuForOrganizationSubject;
    const organizationConf = this.confService.latestOrganizationConfSubject;

    this.combineLatestSubscription = combineLatest([unifiedMenu, organizationConf]).subscribe(([unifiedMenu0, conf0]) => {
      const sites = conf0[organization].site;
      const unifiedMenuDoc: UnifiedMenuDoc[] = cloneDeep(unifiedMenu0);
      const unifiedMenuForSite: UnifiedMenuForSite[] = [];

      // Site의 UnifiedMenu[]를 초기화
      const siteKeys = Object.keys(sites);
      siteKeys.forEach(site => {
        unifiedMenuForSite.push({
          site,
          unifiedMenu: []
        });
      })

      // UnifiedMenu를 Site별로 정리한다.
      for (const shop of unifiedMenuDoc) {
        if (shop.baeminShopInfo && shop.baeminShopInfo.Ord_Avail_Yn) {
          unifiedMenuForSite.forEach(menuForSite => {
            const rooms = sites[menuForSite.site].room;
            const closedRoomList = this.getClosedRoomList(rooms);

            if ((menuForSite.site === shop.site) && (closedRoomList.indexOf(shop.room) === -1)) {
              !this.isDuplicatedShopName(shop, menuForSite.unifiedMenu) && menuForSite.unifiedMenu.push(shop);
            }
          });
        }
      }
      
      this.sites = sites;
      this.unifiedMenuForSite = unifiedMenuForSite;
      this.isloading = this.isContentsLoaded();
    });
  }

  isDuplicatedShopName(shop: UnifiedMenuDoc, unifiedMenu: UnifiedMenu[]) {
    const result = unifiedMenu.find(menu => (shop.room === menu.room) && (shop.shopName === menu.shopName));
    return result ? true : false;
  }

  isContentsLoaded() {
    return !(this.unifiedMenuForSite.length > 0 && this.unifiedMenuForSite[0].unifiedMenu.length > 0);
  }

  getClosedRoomList(rooms: { [roomKey: string]: OrganizationRoom }) {
    const closedRoomList: string[] = [];

    // 업소상태(live)와 테스트(virtual, 옥숙연구소)여부를 확인한다.
    Object.keys(rooms).forEach(roomkey => {
      if (!rooms[roomkey].live || rooms[roomkey].virtual) {
        closedRoomList.push(roomkey);
      }
    });
    return closedRoomList;
  }

  observeCurrentSite() {
    this.currentSiteSubscription = this.currentSiteService.latestCurrentSiteSubject.subscribe(site => {
      this.currentSite = site;
    });
  }

  syncUrlForSite() {
    this.location.onUrlChange(url => {
      const site = url.split('/')[1];
      if (site !== this.currentSite) {
        this.location.go(`/${this.currentSite}`);
      }
    });
  }

  setPlaceHolder(amount: number = 8) {
    const placeholderForShop = [];
    for (let i = 0; i < amount; i++) {
      placeholderForShop.push('placeholder for shop');
    }

    this.placeholderForShop = placeholderForShop;
  }

  observeShoppingCart() {
    this.shoppingCartSubscription = this.shoppingCartService.latestUnifiedOrderFoodsSubject.subscribe(unifiedOrderFoods => {
      this.productsInCart = unifiedOrderFoods.reduce((sum, food) => sum + food.foodQty, 0);
    });
  }

  /******************************************************************
   * [Routing]
   ******************************************************************/
  goShoppingCart() {
    this.router.navigate(['./cart'], { relativeTo: this.route });
  }

  goShop(shopNo: string) {
    // this.router.navigate([ `/${this.currentSite}/${shopNo}` ], { replaceUrl: true });
    this.router.navigate([ `/${this.currentSite}/${shopNo}` ]);
  }

  openDestMap(siteKey: string) {
    const { lat, lng, name } = this.sites[siteKey];
    // tslint:disable-next-line: max-line-length
    const placeName = encodeURI(`${name}`).replace(/,/g, ''); // COMMA 제거
    const url = 'http://map.daum.net/link/map/' + placeName + `,${lat},${lng}`;

    // refer : http://apis.map.daum.net/web/guide/#bigmapurl
    // http://map.daum.net/?urlX=506255.0&urlY=1113450.0&name=%EB%85%BC%ED%98%84%EB%8F%99%2B170-14
    window.open(
      // tslint:disable-next-line: max-line-length
      url,
      'centerscreen,alwaysRaised,noreferrer'
    );
  }
}
