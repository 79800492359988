import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslationDoc, TranslationDocs } from './schema';

const collectionPath = 'translation';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  translation: TranslationDocs = {};
  latestTranslationSubject = new BehaviorSubject<TranslationDocs>({});
  subscription: Subscription;

  constructor(
    private db: AngularFirestore,
  ) {
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  observe() {
    const queryFn: QueryFn = ref => {
      return ref.where('organization', '==', 'ghostkitchen');
    };

    const translationCollection = this.db.collection<TranslationDoc>(collectionPath, queryFn);
    translationCollection.valueChanges().subscribe(docs => {
      const translationDocs = {};

      for (const doc of docs) {
        translationDocs[doc.room] = doc;
      }

      this.translation = translationDocs;

      this.latestTranslationSubject.next(this.translation);
    });
  }
}
