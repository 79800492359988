import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { firestore } from 'firebase/app';
import { BehaviorSubject, Subscription } from 'rxjs';

import { KiccCardOrder, KiccResult } from './schema-kicc';
import { KiccCardOrderDoc, KiccResultDoc, UnifiedOrder } from './schema';

const kiccOrderServicePath = 'kiccOrder';
const kiccResultServicePath = 'kiccResult';

@Injectable({
  providedIn: 'root'
})
export class KiccOrderService {
  kiccResult: KiccResultDoc[];
  latestKiccResultSubject = new BehaviorSubject<KiccResultDoc[]>([]);
  subscription: Subscription;

  constructor(private db: AngularFirestore) { }

  async createKiccOrder(kiccOrder: KiccCardOrder, unifiedOrder: Partial<UnifiedOrder>) {
    const docRef0 = this.db.firestore.collection(kiccOrderServicePath).doc();
    const id = `kicc-${docRef0.id}`;

    const docRef = this.db.firestore.collection(kiccOrderServicePath).doc(id);

    const doc: Partial<KiccCardOrderDoc> = {...kiccOrder,
      _id: id,
      _bizNo: docRef0.id,
      _organization: unifiedOrder.organization,
      _site: unifiedOrder.site,
      _room: unifiedOrder.room,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp
    };

    await this.db.doc<Partial<KiccCardOrderDoc>>(docRef).set(doc);
  }

  /**
   * 핸드폰 번호로 결제내역 DB조회
   */
  observeKiccResult(key: 'userTel', value: string) {
    const queryFn: QueryFn = ref => {
      const query = ref.where(key, '==', value);
      return query;
    };

    const collection = this.db.collection<KiccResultDoc>(kiccResultServicePath, queryFn);

    this.subscription = collection.valueChanges().subscribe(doc => {
      this.kiccResult = doc;
      this.latestKiccResultSubject.next(doc);
    });
  }
}
