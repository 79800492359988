import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { UnifiedMenuService } from './core/unified-menu.service';
import { debugLog } from './core/util';

/**
 * 개별 페이지를 표시하기 전에 준비해야 할 것이 있으면 기다리도록 한다.
 * - unifiedMenu
 */
@Injectable({
  providedIn: 'root'
})
export class ShopGuardService implements CanActivate {
  constructor(
    private unifiedMenuService: UnifiedMenuService
  ) { }

  // setLiveShop
  promiseForInit(shopNo: string) {
    return new Promise<boolean>((resolve) => {
      resolve(true);

      this.unifiedMenuService.restartObservingForShop(shopNo);

      this.unifiedMenuService.latestUnifiedMenuForShopSubject.subscribe(docs => {
        if (this.unifiedMenuService.unifiedMenuForShop && docs) {
          resolve(true);
        }
      });
    });
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const shopNo = route.paramMap.get('shop');
    return this.promiseForInit(shopNo);
  }
}

