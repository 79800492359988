import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnifiedMenuService } from '../../core/unified-menu.service';
import { ShoppingCartService } from '../../core/shopping-cart.service';
import { UnifiedMenuDoc, UnifiedMenuFood, UnifiedOrderFood } from '../../core/schema';
@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit, OnDestroy {
  currentSite: string;
  unifiedMenusubscription: Subscription;
  shoppingCartSubscription: Subscription;

  unifiedMenu: UnifiedMenuDoc;
  unifiedOrderFoods: UnifiedOrderFood[] = [];

  // UI 표시용
  totalQty = 0;
  totalAmount = 0;

  constructor(
    private unifiedMenuService: UnifiedMenuService,
    private shoppingCartService: ShoppingCartService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    if (this.unifiedMenusubscription) {
      this.unifiedMenusubscription.unsubscribe();
    }
    if (this.shoppingCartSubscription) {
      this.shoppingCartSubscription.unsubscribe();
    }
  }

  initialize() {
    this.currentSite = this.route.snapshot.paramMap.get('site');

    this.observeUnifiedMenu();
    this.observeShoppingCart();
  }

  /******************************************************************
   * [Observing]
   ******************************************************************/
  observeUnifiedMenu() {
    this.unifiedMenusubscription = this.unifiedMenuService.latestUnifiedMenuForShopSubject.subscribe(unifiedMenu0 => {
      this.unifiedMenu = unifiedMenu0;
    });
  }

  observeShoppingCart() {
    this.shoppingCartSubscription = this.shoppingCartService.latestUnifiedOrderFoodsSubject.subscribe(unifiedOrderFoods0 => {
      this.totalAmount = unifiedOrderFoods0.reduce((sum, food) => sum + food.foodOrdPrice, 0);
      this.totalQty = unifiedOrderFoods0.reduce((sum, food) => sum + food.foodQty, 0);
      this.unifiedOrderFoods = unifiedOrderFoods0;
    });
  }

  /******************************************************************
   * [Routing]
   ******************************************************************/
  goToMenuForm(url: string, food: UnifiedMenuFood, $event: Event) {
    $event.preventDefault();
    const shopNo = this.route.snapshot.paramMap.get('shop');

    this.router.navigate([ url ], {
      state: { food, shopNo },
      relativeTo: this.route
    });
  }

  goOrder() {
    this.router.navigate(['../cart'], { relativeTo: this.route });
  }
}
