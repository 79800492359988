import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { MenuFormComponent } from './menu-form/menu-form.component';
import { OrderFormComponent } from './order-form/order-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderResultComponent } from './order-result/order-result.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    MenuFormComponent,
    OrderFormComponent,
    OrderResultComponent
  ]
})
export class FormModule { }
