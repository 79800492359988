import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './auth-guard.service';
import { ShopGuardService } from './shop-guard.service';

import { SitesComponent } from './pages/sites/sites.component';
import { ShopComponent } from './pages/shop/shop.component';
import { MenuFormComponent } from './form/menu-form/menu-form.component';
import { OrderFormComponent } from './form/order-form/order-form.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { SitesGuardService } from './site-guard.service';
import { MenuGuardService } from './menu-guard.service';
import { HomeComponent } from './pages/home/home.component';
import { InitGuardService } from './init-guard.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [InitGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeComponent
      },
      {
        path: ':site/order',
        pathMatch: 'full',
        component: OrderFormComponent
      },
      {
        path: ':site/cart',
        pathMatch: 'full',
        component: ShoppingCartComponent
      },
      {
        path: ':site/:shop/:food',
        pathMatch: 'full',
        component: MenuFormComponent,
        canActivate: [MenuGuardService],
      },
      {
        path: ':site/:shop',
        pathMatch: 'full',
        component: ShopComponent,
        canActivate: [ShopGuardService],
      },
      {
        path: ':site',
        pathMatch: 'full',
        component: SitesComponent,
        canActivate: [SitesGuardService],
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'gk-kangnam'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
