import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AuthService } from './core/auth.service';
import { AlertNoticeService } from './shared/alert-notice/alert-notice.service';
import { UserService } from './core/user.service';
import { VersionService } from './core/version.service';
import { LogService } from './core/log.service';
import { GtagService } from './core/gtag.service';
import { debugLog } from './core/util';
import { CurrentSiteService } from './core/current-site.service';
import { Subscription } from 'rxjs';
import { ConfService } from './core/conf.service';
import { OrganizationSite } from './core/schema';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  myVersion: string;
  latestVersion: string;

  routeAnimation = true;
  currentUrl = '';
  email = 'orderhere1@toe.cloud';
  sites: {
    [site: string]: OrganizationSite
  };

  private versionSubscription: Subscription = null;
  private organizationConfSubscription: Subscription = null;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private confService: ConfService,
    private authService: AuthService,
    private userService: UserService,
    private versionService: VersionService,
    private alertNoticeService: AlertNoticeService,
    private logService: LogService,
    private gtagService: GtagService,
    private location: Location,
    private router: Router,
    private menu: MenuController,
    private currentSiteService: CurrentSiteService
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    await this.platform.ready();
    this.statusBar.styleDefault();
    this.statusBar.backgroundColorByHexString('#ffffff');
    this.splashScreen.hide();
  }

  ngOnInit() {
    this.signIn();
    this.observeVersion();
    this.observeOrganizationConf();
    this.checkCurrentSite();
  }

  ngOnDestroy() {
    if (this.versionSubscription) {
      this.versionSubscription.unsubscribe();
    }
    if (this.organizationConfSubscription) {
      this.organizationConfSubscription.unsubscribe();
    }
  }

  observeVersion() {
    this.myVersion = this.versionService.myVersion;
    this.latestVersion = this.versionService.latestVersion;

    this.versionSubscription = this.versionService.latestVersionSubject.subscribe(lastesVersion => {
      this.latestVersion = lastesVersion;
      if (this.myVersion !== this.latestVersion) {
        this.alertNoticeService.blockNoticeAlertConfirm('새 버전이 있습니다.<br>업데이트합니다.', () => this.reload());
      }
    });
  }

  observeOrganizationConf() {
    const organization = 'ghostkitchen';

    this.organizationConfSubscription = this.confService.latestOrganizationConfSubject.subscribe(conf => {
      if (Object.keys(conf).length > 0) {
        this.sites = conf[organization].site;
      }
    });
  }

  reload() {
    this.logService.info(`Site : 버튼 '업데이트'`);
    window.location.reload();
  }

  checkCurrentSite() {
    this.location.onUrlChange((url: string) => {
      const urls = url.split('/');
      this.currentUrl = url;
      
      this.routeAnimation = !(urls.length < 3);
      /**
       * Site간의 이동시 Reload를 막기위해 Route대신 Location을 이용한다.
       * 단 location.go를 통해 변경한 URL은 ion-router-outlet에서 무시되기 때문에
       * 첫 진입시에만 URL에서 site를 가져오고, 이 후 변경한 site정보는 따로 관리한다.
       */
      if (!this.currentSiteService.currentSite) {
        this.currentSiteService.setCurrentSite(urls[1]);
      }
      this.gtagService.page(url);
    });
  }

  changeSite(site: string) {
    this.currentUrl = `/${site}`;
    this.currentSiteService.setCurrentSite(site);
    this.location.go(`/${site}`);
    this.menu.close();
  }

  async signIn() {
    try {
      const isSignIn = await this.authService.signinWithEmailAndPassword(this.email, 'B71B7852-6252-4CCA-A3DD-17C81DFF16B3');
      if (isSignIn) {
        this.userService.observe(this.email);
        this.confService.observeOrganizationConf();

        const myVersion = this.versionService.myVersion;
        this.myVersion = myVersion;
        debugLog(`myVersion = ${myVersion}`);
        this.logService.info(`로그인 성공. myVersion = ${myVersion}`, this.email );

        // 최초 로그인 후 Route - 입력된 site가 있는경우 해당 site로 이동
        if (this.currentUrl.length > 0) {
          this.router.navigate([this.currentUrl]);
        } 
      }
    } catch (error) {
      this.alertNoticeService.noticeAlert(error);
      this.logService.error(`로그인 실패 : ${error}`);
      this.router.navigate(['/redirect']);
    }
  }
}
