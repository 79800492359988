//
// 최근 업데이트 : 2020-02-16
//

// tslint:disable: max-line-length
// tslint:disable: no-null-keyword
import { firestore } from 'firebase/app';

import {
  BaeminCeoOrderStatus,
  BaeminAppOrderStatus,
  BaeminAppListOrder,
  BaeminAppDetailOrder,
  BaeminCeoDetailOrder,
  BaeminUserDetailShop,
  BaeminCeoListShop,
  BaeminCeoDetailShop,
  BaeminCeoMenupan,
  BaeminCeoShopsOpenItem,
  BaeminCeoCampaignsOperationItem
} from './schema-baemin-api';
import {
  FoodflyCeoListOrder,
  FoodflyCeoDetailOrder
} from './schema-foodfly-api';
import {
  CoupangeatsAppOrder
} from './schema-coupangeats-api';
import {
  VroongPosDelivery,
  VroongPosOptionsPickup
} from './schema-vroong-api';
import {
  CombinenetOrderAddRequestBody,
  CombinenetOrderAddResponseBody,
  CombinenetCallbackBody,
  CombinenetOrderStatusResponseBody,
  CombinenetCallback
} from './schema-combinenet-api';
import {
  KiccCardOrder,
  KiccResult
} from './schema-kicc';

//////////////////////////////////////////////////////////////////////////////
//
// 공통
//
export interface CommonConf {
  firestoreCollectionPath: {
    baeminAccount: string;
    vroongAccount: string;

    baeminCeoDetailOrder: string;
    baeminCeoListShop: string;
    baeminCeoDetailShop: string;
    baeminCeoMenupan: string;
    baeminCeoCampaignsOperation: string;

    baeminAppListOrder: string;
    baeminAppDetailOrder: string;

    baeminUserDetailShop: string;

    foodflyCeoListOrder: string;
    foodflyCeoDetailOrder: string;

    vroongPosDelivery: string;
    vroongPosOptionsPickup: string;

    unifiedOrder: string;
    unifiedDelivery: string;

    kakaoSearchAddress: string;

    message: string;
  };
  slack: {
    webhookUrl: string;
    logChannel: string;
  };
  kakao: {
    devsite: string;
    account: string;
    comment: string;
    restapikey: string;
  };
  kakao2: {
    devsite: string;
    account: string;
    comment: string;
    restapikey: string;
  };
  elasticsearch: {
    host: string;
  };
  toast: {
    sender: string;
    appKey: string;
    ccs: string[];
    whitelist?: string[];
  };
  host: {
    talk: string;
  };
}

export interface OrganizationSite {
  name: string;
  printer: string;
  lat: number;
  lng: number;
  room: {
    [roomKey: string]: OrganizationRoom;
  };
}

export interface OrganizationRoom {
  name: string;
  shopName?: string;
  telNo?: string; // 주문서 출력을 기본값이 아닌 다른 값을 사용할 경우. ex) 01034675530
  live: boolean;
  printer: string;
  printFormat: 'text' | 'image';
  translation?: boolean; // menuTranslation 수행 여부
  quiet?: boolean;       // 신규 알림을 하지 않을 때 사용
  virtual?: boolean;     // 테스트 업소와 같이 실제하지 않는 업소
  account: {
    baemin: string[];
    foodfly: string[];
    coupangeats: string[];
    vroong: string[];
    combinenet?: string[];
  };

  // UI용
  _roomKey?: string;
  _organizationKey?: string;
  _organizationName?: string;
  _siteKey?: string;
  _siteName?: string;
  _sitePrinter?: string;
}

export interface OrganizationConf {
  [organizationKey: string]: {
    name: string;
    site: {
      [siteKey: string]: OrganizationSite;
    };
  };
}

export type DeliveryVendor = 'vroong';
export type ConfVendor = UnifiedOrderVendor | DeliveryVendor;

//////////////////////////////////////////////////////////////////////////////
//
// conf/index-baemin | conf/index-vroong | conf/index-foodfly
//
export interface IndexConfItem {
  name: string;
  organization: string;
  site: string;
  room: string;
  // shopNo에 따라서 다른 room을 사용할 경우에 존재한다.
  rooms?: {
    [roomKey: string]: string[];  // shopNo[]
  };
}

export interface IndexConf {
  [instanceId: string]: IndexConfItem;
}

//////////////////////////////////////////////////////////////////////////////
//
// /user
//
export interface UserDoc {
  email: string;  // 'samsung14@toe.cloud'
  enabled: boolean;
  role: 'admin' |  'operator' | 'ceo' | 'remoteceo';
  room: string; // 'gk-samsung-14'
}

export interface UserDocs {
  [email: string]: UserDoc;
}

//////////////////////////////////////////////////////////////////////////////
//
// conf/printer
//
export interface PrinterConfItem {
  printerKey?: string;
  printerName: string;
  qzTrayHostname: string;
  usingSecure: false; // WS vs. WSS
}
export interface PrinterConf {
  [printer: string]: PrinterConfItem;
}

//////////////////////////////////////////////////////////////////////////////
//
// /translation
//
export interface TranslationDoc {
  organization: string;
  site: string;
  room: string;
  menuTranslation: {
    [orginalName: string]: string
  };
}

export interface TranslationDocs {
  [room: string]: TranslationDoc;
}

//////////////////////////////////////////////////////////////////////////////
//
// unifiedOrder, unifiedOrderContext
//

// unifiedOrderStatusMappings도 함께 변경한다.
export const enum UnifiedOrderContextStatusCode {
  UNKNOWN     = 0,
  NEW         = 10,
  CEOACCEPTED = 15,  // EXCLUSIVE
  WAITASSIGN  = 17,  // 배차대기 (정렬 순서를 위해 17을 할당)
  ACCEPTED    = 20,
  COOKED      = 40,
  PICKEDUP    = 60,
  COMPLETED   = 70,
  CANCELED    = 80,
  DELETED     = 90,
  STAGING     = 95,  // 현장 결제 이전의 상태
}

export const enum UnifiedOrderStatusCode {
  UNKNOWN   = 0,
  NEW       = 10,
  ACCEPTED  = 20,
  COOKED    = 40,
  PICKEDUP  = 60,
  COMPLETED = 70,
  CANCELED  = 80,
  DELETED   = 90,
  STAGING   = 95,  // 현장 결제 이전의 상태
}

export type DeliveryCherrypickStatus = '??' | 'ghostrider' | '3rd' | 'self' | 'preorder';  // 미정 | 고스트라이더 | 타사 | 업소자체배송 | 예약(진짜 배송은 나중에)
export interface UnifiedOrderContext {
  _id: string;
  // unifiedOrder와 같은 값을 갖는 필드. 필터링과 보안 규칙에 활용하기 위해서 유지한다.
  organization: string;
  site: string;
  room: string;
  orderChannel: UnifiedOrderChannel;
  orderVendor: UnifiedOrderVendor;
  instanceNo: string;  // _bizNo
  orderDate: string;
  orderNo: string;

  createdBy?: 'manual' | 'face' | 'fingerFace';

  // 필수 필드들
  // 항상 존재해야 한다.
  // unifiedOrderStatus를 포함한 최종 상태를 관리한다.
  // unifiedOrderStatus에는 없는 상태를 추가로 보여준다.
  contextStatusCode: UnifiedOrderContextStatusCode;

  // 조리 예상 시간
  cookMinutes?: number;
  // 관리자 메모
  adminMemo?: string;
  // 이벤트 할인
  eventDiscount?: number; // 양의 값을 갖는다.
  // 고스트키친 자체 배달
  isGhostrider?: boolean;
  deliveryCherrypickStatus?: DeliveryCherrypickStatus;
  ghostriderName?: string;
  ghostriderPickupAt?: string; // 찜을 하면 조리 시간과 최소 픽업 시간의 큰 값으로 설정된다.
}

export const UnifiedOrderStatusMap: {
  'baeminCeo': {
    [status in BaeminCeoOrderStatus]: UnifiedOrderStatusCode
  };
  'baeminApp': {
    [status in BaeminAppOrderStatus]: UnifiedOrderStatusCode
  };
} = {
  baeminCeo: {
    OPENED: UnifiedOrderStatusCode.NEW,
    ACCEPTED: UnifiedOrderStatusCode.ACCEPTED,
    CLOSED: UnifiedOrderStatusCode.COMPLETED,
    CANCELLED: UnifiedOrderStatusCode.CANCELED
  },
  baeminApp: {
    NEW: UnifiedOrderStatusCode.NEW,
    RECEIPT: UnifiedOrderStatusCode.ACCEPTED,
    COMPLETED: UnifiedOrderStatusCode.COMPLETED,
    CANCEL: UnifiedOrderStatusCode.CANCELED
  }
};

export type UnifiedOrderChannel = 'app' | 'tel' | 'face';
// 타입 정의에 이용하기 위한 목적
export interface UnifiedOrderVendorKeys {
  baemin: null;
  foodfly: null;
  ddingdong: null;
  ubereats: null;
  coupangeats: null;
  yogiyo: null;
  shuttle: null;
  ghostkitchen: null;
  other: null;
}
export type UnifiedOrderVendor = keyof UnifiedOrderVendorKeys;
// export type UnifiedOrderVendor = 'baemin' | 'foodfly' | 'ddingdong' | 'ubereats' | 'coupangeats' | 'yogiyo' | 'ghostkitchen' | 'other';
export type UnifiedOrderDeliveryType = 'DELIVERY' | 'TAKEOUT' | 'FOODFLY' | 'BAERA' | 'DDINGDONG' | 'UBER' | 'COUPANG' | 'SHUTTLE' | 'HERE';

export interface UnifiedOrderFood {
  foodName: string;
  foodOpts: UnifiedOrderFoodOpt[];
  foodOrdPrice: number; // foodQty까지 포함한 금액
  foodQty: number;
  mergedName: string;

  _uiState?: 'new' | 'added' | 'none'; // UI를 위한 상태 저장용
}

export interface UnifiedOrderFoodOpt {
  optGrpName?: string;
  optName: string;
  optPrice: number;  // 유닛 가격, foodOrdPrice는 전체 개수에 대한 가격
  optQty: number;
}

export interface UnifiedOrder {
  _id: string;
  organization: string;
  site: string;
  room: string;
  orderChannel: UnifiedOrderChannel;
  orderVendor: UnifiedOrderVendor;
  instanceNo: string;  // _bizNo
  orderNo: string;
  shopName: string;
  shopNo: string;
  orderDate: string;
  orderStatusCode: UnifiedOrderStatusCode;
  orderAmount: number; // deliveryTip 제외
  deliveryTip: number;
  deliveryMinutes: number; // baeminAppListOrder와 baeminAppDetailOrder에만 존재한다.
  deliveryType: UnifiedOrderDeliveryType;
  paymentMethod: '선불' | '후불현금' | '후불카드' | 'NA';
  userTel: string; // 01012345617
  orderMsg: string;
  foods: UnifiedOrderFood[];
  // address_raw: string; // 정규화되지 않아서 사용하지 않는다. address_key + address_detail
  address_key: string;
  address_detail: string;
  address_sido: string;
  address_sigungu: string;
  address_dong: string;
  address_jibun: string;
  address_dongH: string;
  address_road: string | null;
  address_building_name: string;
  address_location: {
    lat: number;
    lon: number;
  };
  // TODO: 부릉 요청에 필요한 필드를 일단 함께 생성한다.
  // 주소로부터 필요할 때에 유도하는 방법으로 바꾸는 것도 고려하자.
  vroong: {
    dest_sigungu: string;
    dest_legal_eupmyeondong: string;
    dest_admin_eupmyeondong: string;
    dest_ri: string;
    dest_beonji: string;
    dest_road: string | null;
    dest_building_number: string;
  };
}

//////////////////////////////////////////////////////////////////////////////
//
// unifiedMenu
//
// foodGrpName > foodName > optGrpName > optName 의 4단계 구성이다.
// 배민 구성이 4단계라서 맞추어 구성을 했고
// 단계수가 적은 2단계라면 foodGrp과 optGrp을 1개로 구성하면 2단계가 된다.
//
export interface UnifiedMenu {
  _id: string; // ${room}-${orderVendor}-${shopNo}
  organization: string; // ghostkitchen
  site: string; // gk-samsung
  room: string; // gk-samsung-01
  orderVendor: UnifiedOrderVendor; // baemin
  instanceNo?: string;  // _bizNo
  shopName?: string;
  shopNo?: string;

  baeminShopInfo?: {
    Ord_Avail_Yn: 'Y' | 'N'; // 'N',
    Ct_Cd_Nm: string; // '돈까스·회·일식',
  };

  menus: UnifiedMenuFoodGrp[];
}
export interface UnifiedMenuFoodGrp {
  // 동일 foodGrpName에 대해서 반복되는 필드
  foodGrpName?: string;

  foods: UnifiedMenuFood[];

}
export interface UnifiedMenuFood {
  foodName: string;
  foodCont?: string; // 메뉴 구성 설명, remark는 부가적인 설명. 배민에서 사용
  foodSoldOut: boolean;
  remark?: string;
  imageUrl?: string;

  foodOptGroups: UnifiedMenuFoodOptGrp[];
}
export interface UnifiedMenuFoodOptGrp {
  // 동일 optGrpName에 대해서 반복되는 필드
  optGrpName?: string;
  optGrpMinSel?: number; // 0보다 크다면 필수 옵션이라는 뜻이다.
  optGrpMaxSel?: number;

  _controlType?: 'radio' | 'checkbox'; /***** UI에서 사용하기 위해서 추가 *****/

  foodOpts: UnifiedMenuFoodOpt[];
}
export interface UnifiedMenuFoodOpt {
  optName: string;
  optPrice: number;
  optSoldOut: boolean;

  _optQty?: number; /***** UI에서 사용하기 위해서 추가 *****/
}

export interface VroongPosDeliveryContext {
  _id: string;
  // VroongPosDeliveryoc와 같은 값을 갖는 필드. 필터링과 보안 규칙에 활용하기 위해서 유지한다.
  delivery_number: string;
  created_at: string;

  // overwrite: 높은 우선 순위를 갖는다.
  // 기존 값을 변경할 수 없어서 덮어쓸 때 사용한다.
  applicant_order_number?: string; // bamin-B0CI00BHAB
}

//////////////////////////////////////////////////////////////////////////////
//
// site
//
// site에 종속적인 상태나 설정을 저장한다.
//
export interface SiteDoc {
  _id: string;
  organization: string;
  siteName: string;
  ghostrider: {
    minPickupOption: string;
  };
  _timeMerge?: firestore.Timestamp;
}

export interface SiteDocs {
  [id: string]: SiteDoc;
}

//////////////////////////////////////////////////////////////////////////////
//
// Firestore에 저장되는 형식
//
export type UnifiedOrderMerge = UnifiedOrderDoc & UnifiedOrderContextDoc & {
  // UI 용
  _ui?: {
    relatedVroongDeliveries?: VroongPosDeliveryMerge[];

    relatedCombinenetDeliveries?: CombinenetDeliveryDoc[];

    isPickupAdjusted?: boolean;
    relatedFoodflyCeoListOrder?: FoodflyCeoListOrderDoc;

    // 배차까지 포함한 상태 정보
    unitedStatusCode?: UnifiedOrderContextStatusCode;
    colorNo?: string; // 할당된 color를 지정한다.
    borderColorNo?: string; // 경제색을 지정한다.
    adminName?: string; // adminMemo 작성자, adminMemo에서 분리
    adminMemoContent?: string; // adminMemo에서 앞의 작성자를 제거
  }
};
export interface UnifiedOrderMerges {
  [id: string]: UnifiedOrderMerge;
}

export type UnifiedOrderContextDoc = UnifiedOrderContext & {
  _timeCreate?: firestore.Timestamp;
  _timeMerge?: firestore.Timestamp;
};

export interface UnifiedOrderContextDocs {
  [id: string]: UnifiedOrderContextDoc;
}

export type UnifiedOrderDoc = UnifiedOrder & {
  _timeCreate?: firestore.Timestamp;
  _timeMerge?: firestore.Timestamp;
};

export interface UnifiedOrderDocs {
  [id: string]: UnifiedOrderDoc;
}

export interface PartialUnifiedOrderDocs {
  [id: string]: Partial<UnifiedOrderDoc>;
}

export type UnifiedMenuDoc  = UnifiedMenu & {
  _timeCreate?: firestore.Timestamp;
  _timeMerge?: firestore.Timestamp;
};

export interface UnifiedMenuDocs {
  [id: string]: UnifiedMenuDoc;
}

export type BaeminAppListOrderDoc = BaeminAppListOrder & {
  _id: string;
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
  _timeCreate?: firestore.Timestamp;
  _timeMerge?: firestore.Timestamp;
};

export interface BaeminAppListOrderDocs {
  [id: string]: BaeminAppListOrderDoc;
}

export type BaeminAppDetailOrderDoc = BaeminAppDetailOrder & {
  _id: string;
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
  _timeCreate?: firestore.Timestamp;
  _timeMerge?: firestore.Timestamp;
};

export interface BaeminAppDetailOrderDocs {
  [id: string]: BaeminAppDetailOrderDoc;
}
export type BaeminCeoDetailOrderDoc = BaeminCeoDetailOrder & {
  _id: string;
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
  _timeCreate?: firestore.Timestamp;
  _timeMerge?: firestore.Timestamp;
};

export interface BaeminCeoDetailOrderDocs {
  [id: string]: BaeminCeoDetailOrderDoc;
}

export type BaeminUserDetailShopDoc = BaeminUserDetailShop & {
  _id: string;
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
};
export interface BaeminUserDetailShopDocs {
  [id: string]: BaeminUserDetailShopDoc;
}

export type FoodflyCeoListOrderDoc = FoodflyCeoListOrder & {
  _id: string;
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
  _shopName: string;
  _timeCreate?: firestore.Timestamp;
  _timeMerge?: firestore.Timestamp;
};
export interface FoodflyCeoListOrderDocs {
  [id: string]: FoodflyCeoListOrderDoc;
}

export type CoupangeatsAppOrderDoc = CoupangeatsAppOrder & {
  _id: string;
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
  _timeCreate?: firestore.Timestamp;
  _timeMerge?: firestore.Timestamp;

  // 상대시각을 절대시각으로 변환한 필드
  absTime?: {
    pickUpRemainingTime?: string;
    deliveryRemainingTime?: string;
    preparationRemainingTime?: string;
    acceptedDuration?: string;
  }
};
export interface CoupangeatsAppOrderDocs {
  [id: string]: CoupangeatsAppOrderDoc;
}

export type FoodflyCeoDetailOrderDoc = FoodflyCeoDetailOrder & {
  _id: string;
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
  _shopName: string;
  _CM_IDX: string;
  _OM_TIME: string;
  _timeCreate?: firestore.Timestamp;
  _timeMerge?: firestore.Timestamp;
};

export interface FoodflyCeoDetailOrderDocs {
  [id: string]: FoodflyCeoDetailOrderDoc;
}

export type VroongPosDeliveryMerge = VroongPosDeliveryDoc & VroongPosDeliveryContextDoc;
export interface VroongPosDeliveryMerges {
  [id: string]: VroongPosDeliveryMerge;
}

export type VroongPosDeliveryDoc = VroongPosDelivery & {
  _id: string; // delivery_number
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;

  // UI용
  _ui?: {
    orderDone?: boolean;
    matchingOrder?: UnifiedOrderMerge;
  }
};
export interface VroongPosDeliveryDocs {
  [id: string]: VroongPosDeliveryDoc;
}

export type VroongPosDeliveryContextDoc = VroongPosDeliveryContext & {
  _id: string; // delivery_number
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
};
export interface VroongPosDeliveryContextDocs {
  [id: string]: VroongPosDeliveryContextDoc;
}

export type VroongPosOptionsPickupDoc = VroongPosOptionsPickup & {
  _id: string; // store_id
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
};
export interface VroongPosOptionsPickupDocs {
  // id는 _id 혹은 _bizNo가 될 수도 있다.
  [id: string]: VroongPosOptionsPickupDoc;
}

export interface VersionDoc {
  omcVer: string;
  ceoVer: string;
  gridVer: string;
  menuVer: string;
  fastfiveVer: string;
  fingerVer: string;
}

export type BaeminCeoListShopDoc = BaeminCeoListShop & {
  _id: string;
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
};
export interface BaeminCeoListShopDocs {
  [id: string]: BaeminCeoListShopDoc;
}

export type BaeminCeoShopsOpenDoc = BaeminCeoShopsOpenItem & {
  _id: string;
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
};
export interface BaeminCeoShopsOpenDocs {
  [id: string]: BaeminCeoShopsOpenDoc;
}

export type BaeminCeoDetailShopDoc = BaeminCeoDetailShop & {
  _id: string;
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
};
export interface BaeminCeoDetailShopDocs {
  [id: string]: BaeminCeoDetailShopDoc;
}

export type BaeminCeoMenupanDoc = BaeminCeoMenupan & {
  _id: string;
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
};
export interface BaeminCeoMenupanDocs {
  [id: string]: BaeminCeoMenupanDoc;
}

export type BaeminCeoCampaignsOperationDoc = BaeminCeoCampaignsOperationItem & {
  _id: string;
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
  _shopNo: string;
};
export interface BaeminCeoCampaignsOperationDocs {
  [id: string]: BaeminCeoCampaignsOperationDoc;
}

export interface VroongAccountDoc {
  bizNo: string;        // 'gk200401',
  apiKey: string;       // 'LK29xhFPpSiPmj0B',
  secretKey: string;    // '2fp5XrMecsGKg7GBNeJ7gW1d6uWteBQ1',
  host: string;         // 'api-test.meshprime.com',
  branch_code: string;  // '1234567',
  sender_name: string;  // '(논현)고스트키친 논현점',
  sender_phone: string; // '025117598',
  name: string;         // '07088822252',
  password: string;     // '1234'
}

export interface VroongAccountDocs {
  [instanceNo: string]: VroongAccountDoc; // instanceNo는 bizNo와 같다.
}

export interface FoodflyAccountDoc {
  name: string; // '치킨혁명',
  bizNo: string; // '2748501102_unjuck1',
  bizName: string; // '키친혁명 언주점',
  id: string; // 'unjuck1',
  pass: string; // '123456',
  master_password: string; // '123456'
}

export interface FoodflyAccountDocs {
  [instanceNo: string]: FoodflyAccountDoc; // instanceNo는 bizNo와 같다.
}

export interface CoupangeatsAccountDoc {
  bizNo: string; // 'gk202201',
  name: string; // '고스트키친 강남역점 22호',
  loginId: string; // '7007',
  password: string; // '@coupang1',
  storeId?: string; // '128313',
  deviceId: string; // '44E3AF72-63A5-49F6-86CF-0C26CEB6D1C7',
  deviceToken: string; // '83baad128b3991db040c4ff49c0d0fbfb2d144e45fc840b8be0759d4656fdae3'
}

export interface CoupangeatsAccountDocs {
  [instanceNo: string]: CoupangeatsAccountDoc; // instanceNo는 bizNo와 같다.
}

export interface CombinenetAccountDoc {
  name: string; // '마라하오 잠실점',
  instanceNo: string; // 'gk300101',
  posStoreCode: string; // 'gk300101',
  storeBusiNo: string; // '2381901116',
  storeCode: string; // '8160'
}

export interface CombinenetAccountDocs {
  [instanceNo: string]: CombinenetAccountDoc;
}

/**
 * UnifiedOrder의 주요 필드
 */
export interface CombinenetDeliveryRelatedOrder {
  orderId: string;
  orderChannel: UnifiedOrderChannel;
  orderVendor: UnifiedOrderVendor;
  instanceNo: string;  // _bizNo
  orderNo: string;
  shopName: string;
  shopNo: string;
  orderDate: string;
  orderAmount: number; // deliveryTip 제외
  deliveryTip: number;
  deliveryMinutes: number; // baeminAppListOrder와 baeminAppDetailOrder에만 존재한다.
  deliveryType: UnifiedOrderDeliveryType;
  paymentMethod: '선불' | '후불현금' | '후불카드' | 'NA';
  userTel: string; // 01012345617
  orderMsg: string;
}

export interface CombinenetDeliveryDoc {
  _id: string; // posOrderCode와 동일
  _timeCreate: firestore.Timestamp;
  _timeUpdate?: firestore.Timestamp;
  _timeMerge?: firestore.Timestamp;

  organization: string;
  site: string;
  room: string;

  relatedOrder?: CombinenetDeliveryRelatedOrder;
  orderAddRequest: CombinenetOrderAddRequestBody;
  orderAddResponse?: CombinenetOrderAddResponseBody;
  latestCallback?: CombinenetCallbackBody;
  orderStatus?: CombinenetOrderStatusResponseBody;

  // UI용
  _ui?: {
    orderDone?: boolean;
    matchingOrder?: UnifiedOrderMerge;
  };
}

export interface CombinenetDeliveryDocs {
  [id: string]: CombinenetDeliveryDoc;
}

export type CombinenetCallbackDoc = CombinenetCallback & {
  _id: string;
  _timeCreate: firestore.Timestamp;
};
export interface CombinenetCallbackDocs {
  [id: string]: CombinenetCallbackDoc;
}

export type SimpleNoticeState = 'show' | 'hide' | 'deleted';

// collection: simpleNotice
export interface SimpleNoticeDoc {
  _id: string;
  _timeCreate: firestore.Timestamp;
  _timeUpdate?: firestore.Timestamp;
  _timeMerge?: firestore.Timestamp;

  organization: string;
  site: string;

  state: SimpleNoticeState;
  title: string;
  desc?: string;
}

export interface SimpleNoticeDocs {
  [id: string]: SimpleNoticeDoc;
}

export interface FaceConf {
  isClosed: boolean;
  isTemporaryClosed: boolean;
  msgClosed: string;
  msgTemporaryClosed: string;
}

export interface BlacklistConf {
  baeminShops: {
    shopName: string;
    shopNo: string;
  }[];
}


//////////////////////////////////////////////////////////////////////////////
//
// KICC
//
export type KiccCardOrderDoc = KiccCardOrder & {
  _id: string;
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
  _timeCreate?: firestore.Timestamp;
  _timeMerge?: firestore.Timestamp;
};

export type KiccResultDoc = KiccResult & UnifiedOrder & {
  _id: string;
  _bizNo: string;
  _organization: string;
  _site: string;
  _room: string;
  _timeCreate?: firestore.Timestamp;
  _timeMerge?: firestore.Timestamp;
};

export const SiteKRMap = new Map<string, string>([
  ['kangnam', '강남역점'],
  ['samsung', '삼성점'],
  ['songpa', '송파점']
]);

export interface UserState {
  [key: string]: string | object;
}
export interface UserAddress {
  address_key: string;
  address_dongH: string;
  address_road: string | null;
  address_building_name: string;
  address_sido: string;
  address_sigungu: string;
  address_dong: string;
  address_jibun: string;
  address_detail: string;
  address_location: {
    lat: number;
    lon: number;
  };
  vroong: {
    dest_sigungu: string;
    dest_legal_eupmyeondong: string;
    dest_admin_eupmyeondong: string;
    dest_ri: string;
    dest_beonji: string;
    dest_road: string | null;
    dest_building_number: string;
  };
}

export type OrderStep =
    'order' // 주문을 위한 최초 페이지 Finger 화면 노출.
  | 'ongoing' // 주문 진행중 Iframe 화면 노출. (KICC에서 제공하는 화면)
  | 'finish'; // 최종 완료
