import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { ToastNoticeService } from '../../shared/toast-notice/toast-notice.service';
import { LoadingService } from 'src/app/shared/loading/loading.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent {
  email = 'orderhere1@toe.cloud';

  constructor(
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
    private toastNoticeService: ToastNoticeService,
  ) {
  }

  async onSubmit() {
    try {
      await this.loadingService.presentLoading();
      const isSignIn = await this.authService.signinWithEmailAndPassword(this.email, 'B71B7852-6252-4CCA-A3DD-17C81DFF16B3');
      await this.loadingService.dismissLoading();
      if (isSignIn) {
        this.router.navigate(['/']);
      }
    } catch (error) {
      await this.loadingService.dismissLoading();
      this.toastNoticeService.noticeToast(error.message);
    }
  }
}
