import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddressSearchModalComponent } from './address-search-modal.component';
import { AugmentedAddress } from 'src/app/core/schema-message';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private modalController: ModalController
  ) { }

  async presentAddressModal(getAddress?: (response: AugmentedAddress) => void) {
    const modal = await this.modalController.create({
      component: AddressSearchModalComponent,
      componentProps: { getAddress }
    });
    await modal.present();
  }
}
