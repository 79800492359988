import { Component, OnInit, Input } from '@angular/core';
import { UnifiedOrder, SiteKRMap } from '../../core/schema';
import { PayTypeMap, KiccResult } from '../../core/schema-kicc';
import { normalizeTel } from '../../core/util';
import { ConfService } from 'src/app/core/conf.service';

@Component({
  selector: 'app-order-result',
  templateUrl: './order-result.component.html',
  styleUrls: ['./order-result.component.scss']
})
export class OrderResultComponent implements OnInit {
  @Input() kiccResult: KiccResult;
  @Input() unifiedOrder: UnifiedOrder;
  @Input() step: string;
  payType = '';
  userTel: string;
  siteKR: string;

  constructor(
    private confService: ConfService,
  ) { }

  ngOnInit() {
    this.getPayType();
    this.userTel = normalizeTel(this.unifiedOrder.userTel);

    const siteKey = this.unifiedOrder.site;
    const siteOnly = siteKey.split('-')[1];
    this.siteKR = '고스트키친 ' + SiteKRMap.get(siteOnly);
  }

  getPayType() {
    this.payType = PayTypeMap.get(this.kiccResult.pay_type);
  }

  openDestMap(siteKey: string) {
    const organization = 'ghostkitchen';
    const { lat, lng, name } = this.confService.organizationConf[organization].site[siteKey];
    
    const placeName = encodeURI(`${name}`).replace(/,/g, ''); // COMMA 제거
    const url = 'http://map.daum.net/link/map/' + placeName + `,${lat},${lng}`;

    // refer : http://apis.map.daum.net/web/guide/#bigmapurl
    // http://map.daum.net/?urlX=506255.0&urlY=1113450.0&name=%EB%85%BC%ED%98%84%EB%8F%99%2B170-14
    window.open(
      // tslint:disable-next-line: max-line-length
      url,
      'centerscreen,alwaysRaised,noreferrer'
    );
  }
}
