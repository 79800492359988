import { Component, OnInit, OnDestroy } from '@angular/core';

import { OrganizationSite } from '../../core/schema';
import { ConfService } from 'src/app/core/conf.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  imageUrlPrefix = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/site/'
  sites: {
    [site: string]: OrganizationSite
  };

  private organizationConfSubscription: Subscription = null;

  constructor(
    private confService: ConfService,
    private router: Router
  ) { }

  ngOnInit() {
    this.observeOrganizationConf();
  }

  ngOnDestroy() {
    if (this.organizationConfSubscription) {
      this.organizationConfSubscription.unsubscribe();
    }
  }

  observeOrganizationConf() {
    const organization = 'ghostkitchen';

    this.organizationConfSubscription = this.confService.latestOrganizationConfSubject.subscribe(conf => {
      this.sites = conf[organization].site;
    });
  }

  goSite(site: string) {
    this.router.navigate([ `/${site}` ]);
  }
}
