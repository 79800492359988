import get from 'lodash/get';
import { Injectable } from '@angular/core';
import { firestore } from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';

import { instanceId } from './common';
import { UtilService } from './util.service';
import {
  Log,
  LogLevel
} from './schema-message';
import { UserService } from './user.service';

const collectionPath = 'log';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(
    private db: AngularFirestore,
    private utilService: UtilService,
    private userService: UserService
  ) {
    console.log(`log instance ID = ${instanceId}`);
  }

  /**
   * @param email: 아직 userService에서 정보를 못 찾아오는 단계에세 사용한다.
   */
  private async send(level: LogLevel, message: string, email?: string) {
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;

    const doc: Log = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      from: {
        class: 'finger',
        instanceNo: instanceId,
        account: email ? email : get(this.userService, ['user', 'email'], ''),
      },
      level,
      message
    };

    return await this.db.doc<Log>(docRef).set(doc);
  }

  public async debug(message: string, email?: string) {
    await this.send('debug', message, email);
  }
  public async info(message: string, email?: string) {
    await this.send('info', message, email);
  }
  public async warn(message: string, email?: string) {
    await this.send('warn', message, email);
  }
  public async error(message: string, email?: string) {
    await this.send('error', message, email);
  }

  public async withToastrError(message: string, title?: string, timeout?: number) {
    this.utilService.toastrError(message, title, timeout);
    await this.send('error', `${title ? title + ': ' : ''} ${message}`);
  }

  public async withToastrCatch(reason: string) {
    this.utilService.toastrCatch(reason);
    await this.send('error', reason);
  }
}
