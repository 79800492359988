import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SitesComponent } from './sites/sites.component';
import { IonicModule } from '@ionic/angular';
import { ShopComponent } from './shop/shop.component';
import { FormModule } from '../form/form.module';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { HomeComponent } from './home/home.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormModule
  ],
  declarations: [
    HomeComponent,
    SitesComponent,
    ShopComponent,
    ShoppingCartComponent,
  ]
})
export class PagesModule { }
