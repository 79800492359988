import { Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ActionSheetOrderService {
  actionSheet: HTMLIonActionSheetElement;

  constructor(
    private actionSheetController: ActionSheetController
  ) { }

  async presentActionSheet(leftAction: () => void, rightAction: () => void) {
    this.actionSheet = await this.actionSheetController.create({
      header: '주문선택',
      buttons: [{
        text: '테이크아웃',
        handler: leftAction
      }, {
        text: '배달',
        handler: rightAction
      }],
      cssClass: 'order-action-sheet'
    });

    await this.actionSheet.present();
  }

  async dissmissActionSheet() {
    await this.actionSheet.dismiss();
  }
}
