import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { PrinterConf, OrganizationConf, OrganizationRoom, IndexConf, IndexConfItem } from './schema';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ConfVendor, FaceConf, BlacklistConf } from './schema';
import { trimOrganization } from './util';
import { UtilService } from './util.service';

const confCollectionPath = 'conf';

@Injectable({
  providedIn: 'root'
})
export class ConfService {
  printerConf: PrinterConf = {};
  latestPrinterConfSubject = new BehaviorSubject<PrinterConf>({});
  printerSubscription: Subscription;

  organizationConf: OrganizationConf = {};
  latestOrganizationConfSubject = new BehaviorSubject<OrganizationConf>({});
  organizationSubscription: Subscription;

  faceConf: FaceConf = {
    isClosed: false,
    isTemporaryClosed: false,
    msgClosed: '서버에서 메시지 가져오는 중...',
    msgTemporaryClosed: '서버에서 메시지 가져오는 중...',
  };
  lastestFaceConfSubject = new BehaviorSubject<FaceConf>(this.faceConf);
  faceSubscription: Subscription;

  blacklistConf: BlacklistConf = {
    baeminShops: []
  };
  lastestBlacklistConfSubject = new BehaviorSubject<BlacklistConf>(this.blacklistConf);
  blacklistSubscription: Subscription;

  organizationMappings = {};
  siteMappings = {};
  roomMappings = {};

  indexConf: {
    [vendor: string]: IndexConf
  } = {};
  latestIndexConfSubject: {
    [vendor: string]: BehaviorSubject<IndexConf>;
  } = {};
  indexSubscription: {
    [vendor: string]: Subscription;
  } = {};

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService
  ) {
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  observePrinterConf() {
    const docRef = this.db.doc<PrinterConf>(`${confCollectionPath}/printer`);

    this.printerSubscription = docRef.valueChanges().subscribe(doc => {
      this.printerConf = doc;
      this.latestPrinterConfSubject.next(doc);
      // console.dir(printer);
    }, error => {
      this.utilService.toastrError(`observePrinterConf에서 에러 발생 : ${error}`);
    });
  }

  observeOrganizationConf() {
    const docRef = this.db.doc<OrganizationConf>(`${confCollectionPath}/organization`);

    this.organizationSubscription = docRef.valueChanges().subscribe(doc => {
      this.organizationConf = doc;
      this.updateRefData();

      this.latestOrganizationConfSubject.next(doc);
    }, error => {
      this.utilService.toastrError(`observeOrganizationConf에서 에러 발생 : ${error}`);
    });
  }

  observeIndexConf(vendor: ConfVendor) {
    const docRef = this.db.doc<IndexConf>(`${confCollectionPath}/index-${vendor}`);

    this.latestIndexConfSubject[vendor] = new BehaviorSubject<IndexConf>({});

    this.indexSubscription[vendor] = docRef.valueChanges().subscribe(doc => {
      this.indexConf[vendor] = doc;
      this.latestIndexConfSubject[vendor].next(doc);
    }, error => {
      this.utilService.toastrError(`observeIndexConf(${vendor})에서 에러 발생 : ${error}`);
    });
  }

  observeIndexConfs() {
    for (const vendor of (['baemin', 'foodfly', 'vroong'] as ConfVendor[])) {
      this.observeIndexConf(vendor);
    }
  }

  observeFace() {
    const docRef = this.db.doc<FaceConf>(`${confCollectionPath}/face`);

    this.faceSubscription = docRef.valueChanges().subscribe(doc => {
      this.faceConf = doc;
      this.lastestFaceConfSubject.next(doc);
    }, error => {
      this.utilService.toastrError(`observeFace()에서 에러 발생 : ${error}`);
    });
  }

  observeBlacklist() {
    const docRef = this.db.doc<BlacklistConf>(`${confCollectionPath}/blacklist`);

    this.blacklistSubscription = docRef.valueChanges().subscribe(doc => {
      this.blacklistConf = doc;
      this.lastestBlacklistConfSubject.next(doc);
    }, error => {
      this.utilService.toastrError(`observeBlacklist()에서 에러 발생 : ${error}`);
    });
  }

  updateRefData() {
    const organizationMappings = {};
    const siteMappings = {};
    const roomMappings = {};

    for (const organizationKey of Object.keys(this.organizationConf)) {
      organizationMappings[organizationKey] = this.organizationConf[organizationKey].name;

      const site = this.organizationConf[organizationKey].site;
      for (const siteKey of Object.keys(site)) {
        siteMappings[siteKey] = site[siteKey].name;

        const room = site[siteKey].room;
        for (const roomKey of Object.keys(room)) {
          roomMappings[roomKey] = trimOrganization(room[roomKey].name);
        }
      }
    }

    this.organizationMappings = organizationMappings;
    this.siteMappings = siteMappings;
    this.roomMappings = roomMappings;
  }

  indexConfForInstanceId(vendor: ConfVendor, instanceId: string): IndexConfItem {
    if (this.indexConf[vendor] && this.indexConf[vendor][instanceId]) {
      return this.indexConf[vendor][instanceId];
    } else {
      return {
        name: '',
        organization: '',
        site: '',
        room: ''
      };
    }
  }

  /**
   * 직접 입력한 주문인 경우에는 instanceId가 없기 때문에 roomKey로 printer를 찾아야 한다.
   */
  printerForRoomKey(roomKey: string): string {
    const room = this.roomForRoomKey(roomKey);

    return room ? room.printer : undefined;
  }

  printerForInstanceId(vendor: ConfVendor, instanceId: string): string {
    const indexConfItem = this.indexConfForInstanceId(vendor, instanceId);

    try {
      const printer = this.organizationConf[indexConfItem.organization].site[indexConfItem.site].room[indexConfItem.room].printer;
      return printer;
    } catch (err) {
      this.utilService.toastrCatch(`room(instanceId = ${instanceId}) printer를 찾을 수 없어요`);
    }

    return 'NA';
  }

  sitePrinterForRoomKey(roomKey: string): string {
    const room = this.roomForRoomKey(roomKey);

    return room ? room._sitePrinter : undefined;
  }

  /**
   * site에 속한 데스크용 프린터를 찾는다.
   */
  sitePrinterForInstanceId(vendor: ConfVendor, instanceId: string): string {
    const indexConfItem = this.indexConfForInstanceId(vendor, instanceId);

    try {
      const printer = this.organizationConf[indexConfItem.organization].site[indexConfItem.site].printer;
      return printer;
    } catch (err) {
      this.utilService.toastrCatch('site printer를 찾을 수 없어요');
    }

    return 'NA';
  }

  /**
   * 해당 room에 대해서 translation 값을 읽는다.
   */
  translateForRoom(organization: string, site: string, room: string) {
    try {
      const translation = this.organizationConf[organization].site[site].room[room].translation;

      return translation ? translation : false;
    } catch (error) {
      this.utilService.toastrCatch('translation을 찾을 수 없어요');
    }
  }

  /**
   * 해당 room에 대해서 vroong instance No(계정) 배열을 리턴한다.
   */
  public vroongInstanceNosForRoom(organization: string, site: string, room: string) {
    try {
      const instanceNos = this.organizationConf[organization].site[site].room[room].account.vroong;
      return instanceNos;
    } catch (error) {
      this.utilService.toastrCatch('instanceNos를 찾을 수 없어요');
    }
  }

  public roomForRoomKey(roomKey: string): OrganizationRoom {
    for (const organizationKey of Object.keys(this.organizationConf)) {
      const organization = this.organizationConf[organizationKey];

      for (const siteKey of Object.keys(organization.site)) {
        const site = organization.site[siteKey];

        for (const $roomKey of Object.keys(site.room)) {
          if ($roomKey === roomKey) {
            const room = site.room[$roomKey];

            return {
              ...room, ...{
                _roomKey: $roomKey,
                _organizationKey: organizationKey,
                _organizationName: organization.name,
                _siteKey: siteKey,
                _siteName: site.name,
                _sitePrinter: site.printer
              }
            };
          }
        }
      }
    }

    return undefined;
  }
}
