import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ModalController, IonContent, IonInput } from '@ionic/angular';
import { kakao_keyword_address_search } from '../../core/kakao-address-search-api';
import { KakaoKeywordAddressDocument, KakaoKeywordSearchAddress } from '../../core/schema-kakao-api';
import { MessageService } from '../../core/message.service';
import { Message, AugmentedAddress } from '../../core/schema-message';
import { ToastNoticeService } from '../toast-notice/toast-notice.service';
import { LoadingService } from '../loading/loading.service';

@Component({
  selector: 'app-address-search-modal',
  templateUrl: './address-search-modal.component.html',
  styleUrls: ['./address-search-modal.component.scss']
})
export class AddressSearchModalComponent implements OnInit {
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild('Search', { static: true }) searchInput: IonInput;
  @Input() getAddress: (resopnse: AugmentedAddress ) => void;
  addressForm: FormControl;
  documents: KakaoKeywordAddressDocument[];
  pageNumber = 1;
  pageLength = 1;
  totalCount: number;
  isEnd: boolean;
  size = 15;
  // documents: JusoAddressDocument[];


  constructor(
    private modalController: ModalController,
    private messageService: MessageService,
    private toastNoticeService: ToastNoticeService,
    private loadingService: LoadingService,
  ) { }

  ngOnInit() {
    this.addressForm = new FormControl({
      value: '',
      disabled: false
    }, this.searchAddressValidator());

    this.autoFocus();
  }

  autoFocus() {
    // Modal open 애니메이션이 완료된 후 Focus한다.
    setTimeout(() => {
      this.searchInput.setFocus();
    }, 400);
  }

  async dismissModal() {
    await this.modalController.dismiss();
  }

  async search(page?: number) {
    const keyword = this.addressForm.value;
    if (keyword.length < 1) {
      return;
    }

    if (page === undefined) {
      this.pageNumber = 1;
    }

    try {
      const response = await kakao_keyword_address_search(keyword, page);
      const result = await response.json() as KakaoKeywordSearchAddress;
      this.documents = result.documents;
      this.totalCount = result.meta.total_count;
      this.pageLength = Math.floor(this.totalCount > this.size ? this.totalCount / this.size : 1);

    } catch (error) {
      console.log(error);
    }
  }

  async paginate(direction: 'prev' | 'next') {
    let pageNumber = this.pageNumber;
    if (direction === 'prev' && pageNumber > 1) {
      --pageNumber;
    } else if (pageNumber < this.pageLength) {
      ++pageNumber;
    }

    if (pageNumber === this.pageNumber) {
      return;
    } else {
      this.pageNumber = pageNumber;
      await this.search(pageNumber);
      this.content.scrollToTop();
    }
  }

  searchAddressValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const value = control.value;

      if (value && value.length > 0) {

        // 특수문자 제거
        const expText = /[%=><]/ ;
        if (expText.test(value) === true) {
          this.addressForm.setValue(value.split(expText).join(''));
          return { reason: '특수문자를 입력 할수 없습니다.' };
        }
      } else {
        return { reason: '예) 서초동1321-5 또는 ㅇㅇ빌딩' };
      }
    };
  }

  initForm() {
    this.addressForm.setValue('');
  }

  async pickAddress(rawAddressRoad: string, rawAddressJibun: string) {
    try {
      await this.loadingService.presentLoading();

      const resopnse = await this.augmentAddress(rawAddressRoad, rawAddressJibun);
      this.getAddress(resopnse);
      this.dismissModal();
    } catch (error) {
      this.toastNoticeService.noticeToast(error.message, 'danger');
    } finally {
      this.loadingService.dismissLoading();
    }

  }

  async augmentAddress(rawAddressRoad: string, rawAddressJibun: string) {
    try {
      let resMessage;
      resMessage = await this.messageService.requestAugmentAddress(rawAddressRoad) as Message<'response', 'augmentAddress'>;

      if (resMessage.result === 'success') {
        return resMessage.body;
      } else {
        // 도로명 검색으로 모호한 응답(복수의 응답)이 오는 경우 지번 주소로 한 번 더 시도한다.
        resMessage = await this.messageService.requestAugmentAddress(rawAddressJibun) as Message<'response', 'augmentAddress'>;

        if (resMessage.result === 'success') {
          return resMessage.body;
        }

        throw new Error(`주소 확인 실패 : ${resMessage.reason}`);
      }
    } catch (error) {
      if (error.name === 'TimeoutError') {
        throw new Error('시간 초과 : 명령에 응답을 하지 않습니다.');
      } else {
        throw new Error(`에러 : ${error.message}`);
      }
    }
  }
}

