import fecha from 'fecha';
import { firestore } from 'firebase/app';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import {
  UnifiedOrder,
  UnifiedOrderDoc,
  UnifiedOrderContextDoc,
  UnifiedOrderContext,
  UnifiedOrderContextStatusCode,
  UnifiedOrderStatusCode
} from './schema';
import { WHERE } from '../core/common';
// const collectionPathSuffix = 'Beta';
const collectionPathSuffix = '';
const unifiedOrderCollectionPath = environment.firestoreCollectionPath.unifiedOrder + collectionPathSuffix;
const unifiedOrderContextCollectionPath = 'unifiedOrderContext' + collectionPathSuffix;

@Injectable({
  providedIn: 'root'
})
export class UnifiedOrderService {
  constructor(private db: AngularFirestore) { }

  /**
   *
   * @param openHours 영업 시작 시간
   * @param openMinutes 영업 시작 분
   * @param [atDate=0] 0: 오늘(0시 기준이 아니라 영업시간기준), -1: 어제
   */
  // tslint:disable-next-line: max-line-length
  private observe<T extends UnifiedOrderDoc | UnifiedOrderContextDoc>(collectionPath: string, wheres: WHERE[], openHours: number, openMinutes: number, atDate = 0, orderBy: 'asc' | 'desc' = 'asc') {
    const now = new Date();
    const nowHours = now.getHours();
    const nowMinutes = now.getMinutes();

    // 현재 시각이 오픈 이전이라면 이전 날짜에 대한 주문부터 가져온다.
    if (openHours * 60 + openMinutes > nowHours * 60 + nowMinutes) {
      atDate -= 1;
    }

    const openHHMM = String(openHours).padStart(2, '0') + ':' + String(openMinutes).padStart(2, '0');

    // orderDate: string; // "2019-04-09T18:56:20+0900",
    const atDateString = fecha.format(now.getTime() + atDate * 24 * 3600 * 1000, `YYYY-MM-DDT${openHHMM}:00+0900`);

    console.log(`${this.constructor.name}::observe ${collectionPath} from ${atDateString}`);
    const queryFn: QueryFn = ref => {
      let query = ref.where('organization', '==', 'ghostkitchen');
      wheres.forEach((where: WHERE) => {
        query = query.where(where[0], where[1] as firestore.WhereFilterOp, where[2]);
      });

      query = query.orderBy('orderDate', orderBy);
      query = orderBy === 'asc' ? query.startAt(atDateString) : query.endAt(atDateString);
      return query;
    };

    const orderCollection = this.db.collection<T>(collectionPath, queryFn);

    // 디버깅용
    if (environment.production === false) {
      orderCollection.stateChanges().pipe(
        map(actions => actions.map(action => {
          return { _type: action.type, ...action.payload.doc.data() };
        }))
      ).subscribe(orders => {
        // for (const orderBy of orders) {
        //   console.log(`[${scooter.vendor}] ${scooter.no} '${scooter._type}'`);
        // }
      });
    }

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    const observable = orderCollection.valueChanges();

    return observable;
  }

  public observeOrder(wheres: WHERE[], openHours: number, openMinutes: number, atDate = 0, orderBy: 'asc' | 'desc' = 'asc') {
    return this.observe<UnifiedOrderDoc>(unifiedOrderCollectionPath, wheres, openHours, openMinutes, atDate, orderBy);
  }

  public observeOrderContext(wheres: WHERE[], openHours: number, openMinutes: number, atDate = 0, orderBy: 'asc' | 'desc' = 'asc') {
    return this.observe<UnifiedOrderContextDoc>(unifiedOrderContextCollectionPath, wheres, openHours, openMinutes, atDate, orderBy);
  }

  /**
   * unifiedOrder의 읿부 필드를 직접 변경한다.
   * 주로 manual 주문에 대해서 처리할 때 사용한다.
   */
  async mergeOrder(order: Partial<UnifiedOrder>) {
    const id = order._id;

    if (id == null) {
      throw new Error('_id field must exist');
    }

    const docRef = this.db.firestore.collection(unifiedOrderCollectionPath).doc(id);
    const doc: Partial<UnifiedOrderDoc> = { ...order, ...{
      _timeMerge: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
    }};

    return await this.db.doc<Partial<UnifiedOrderDoc>>(docRef).set(doc, {
      merge: true
    });
  }

  /**
   * unifiedOrderContext doc에서 원하는 필드를 변경한다.
   * unifiedOrderDoc이 생성되면 자동으로 unifiedOrderContextDoc이 생성된다.
   * 여기서는 필요한 필드만 제공하면 된다.
   * @param context 존재하는 필드만 업데이트한다.
   */
  async mergeOrderContext(context: Partial<UnifiedOrderContext>) {
    const id = context._id;

    if (id == null) {
      throw new Error('_id field must exist');
    }

    const docRef = this.db.firestore.collection(unifiedOrderContextCollectionPath).doc(id);
    const doc: Partial<UnifiedOrderContextDoc> = { ...context, ...{
      _timeMerge: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
    }};

    return await this.db.doc<Partial<UnifiedOrderContextDoc>>(docRef).set(doc, {
      merge: true
    });
  }

  async setManualOrder(order: Partial<UnifiedOrder>, isUpdate = false) {
    const doc: Partial<UnifiedOrderDoc> = order;

    if (isUpdate) {
      doc._id = order._id;
      doc._timeMerge = firestore.FieldValue.serverTimestamp() as firestore.Timestamp;
    } else {
      const docRef0 = this.db.firestore.collection(unifiedOrderCollectionPath).doc();

      doc._id = `manual-${docRef0.id}`;
      doc.orderNo = docRef0.id;
      doc._timeCreate = firestore.FieldValue.serverTimestamp() as firestore.Timestamp;
    }

    const docRef = this.db.firestore.collection(unifiedOrderCollectionPath).doc(doc._id);
    // console.dir(doc);
    await this.db.doc<Partial<UnifiedOrderDoc>>(docRef).set(doc);

    return doc;
  }

  async setManualOrderContext(context: Partial<UnifiedOrderContext>) {
    if (context._id == null) {
      throw new Error('context._id가 없네요.');
    }

    const doc = context;

    // console.dir(doc);
    await this.mergeOrderContext(doc);

    return doc;
  }

  /**
   * orderStatusCode와 contextStatusCode를 직접 변경한다.
   */
  async updateOrderStatus(order: UnifiedOrder, newStatus: UnifiedOrderStatusCode) {
    const id = order._id;

    if (id == null) {
      throw new Error('order._id field must exist');
    }

    // 1. unifiedOrder 변경
    await this.mergeOrder({
      _id: id,
      orderStatusCode: newStatus
    });

    // 2. unifiedOrderContext 변경
    await this.mergeOrderContext({
      _id: id,
      contextStatusCode: newStatus as unknown as UnifiedOrderContextStatusCode
    });
  }

  /**
   * @param startDate 조회 시작일
   * @param endDate 조회 종료일
   */
  // tslint:disable-next-line: max-line-length
  private getByDate<T extends UnifiedOrderDoc | UnifiedOrderContextDoc>(collectionPath: string, wheres: WHERE[], startDate: string, endDate: string, orderBy: 'asc' | 'desc' = 'desc') {
    console.log(`${this.constructor.name}::getByDate ${collectionPath} date: ${startDate}~${endDate}`);
    const queryFn: QueryFn = ref => {
      // 조회 순서가 desc 이므로 반대로 startAt, endAt
      let query = ref
        .orderBy('orderDate', orderBy)
        .startAt(endDate)
        .endAt(startDate);

      wheres.forEach((where: WHERE) => {
        query = query.where(where[0], where[1] as firestore.WhereFilterOp, where[2]);
      });

      return query;
    };

    const orderCollection = this.db.collection<T>(collectionPath, queryFn);

    return orderCollection.get();
  }

  public getOrderByDate(wheres: WHERE[], startDate: string, endDate: string, orderBy: 'asc' | 'desc' = 'asc') {
    return this.getByDate<UnifiedOrderDoc>(unifiedOrderCollectionPath, wheres, startDate, endDate, orderBy);
  }

  public getOrderContextByDate(wheres: WHERE[], startDate: string, endDate: string, orderBy: 'asc' | 'desc' = 'asc') {
    return this.getByDate<UnifiedOrderContextDoc>(unifiedOrderContextCollectionPath, wheres, startDate, endDate, orderBy);
  }

  getFingerDocId() {
    const docRef0 = this.db.firestore.collection(unifiedOrderCollectionPath).doc();
    return `finger-${docRef0.id}`;
  }

  async createFingerOrder(order: Partial<UnifiedOrder>, context: Partial<UnifiedOrderContext>, docRefId?: string) {
    let id = docRefId;
    if (!id) {
      id = this.getFingerDocId();
    }

    console.log(order.userTel);
    //
    // 1. UnifiedOrder를 기록한다.
    {
      const docRef = this.db.firestore.collection(unifiedOrderCollectionPath).doc(id);

      const doc: Partial<UnifiedOrderDoc> = {...order, ...{
        _id: id,
        _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      }};

      // console.dir(doc);
      await this.db.doc<Partial<UnifiedOrderDoc>>(docRef).set(doc);
    }

    //
    // 2. UnifiecOrderContext도 함께 생성해야 한다.
    {
      const doc: UnifiedOrderContext = {...{
        _id: id,
        // unifiedOrder와 같은 값을 갖는 필드. 필터링과 보안 규칙에 활용하기 위해서 유지한다.
        organization: order.organization,
        site: order.site,
        room: order.room,
        orderChannel: order.orderChannel,
        orderVendor: order.orderVendor,
        instanceNo: order.instanceNo, // ''
        orderDate: order.orderDate,
        orderNo: order.orderNo, // ''

        createdBy: 'fingerFace',

        // 필수 필드들
        // 항상 존재해야 한다.
        // unifiedOrderStatus를 포함한 최종 상태를 관리한다.
        // unifiedOrderStatus에는 없는 상태를 추가로 보여준다.
        contextStatusCode: order.orderStatusCode as unknown as UnifiedOrderContextStatusCode,

        // 조리 예상 시간
        cookMinutes: 0,
        // 관리자 메모
        adminMemo: ''
      }, ...context};

      await this.mergeOrderContext(doc);
    }

    return;
  }
}
