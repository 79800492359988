import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastNoticeService {

  constructor(
    private toastController: ToastController
  ) { }

  async noticeToast(msg: string, color: string = 'light', timeout?: number) {
    const toast = await this.toastController.create({
      message: msg,
      color,
      duration: timeout ? timeout : 2000
    });
    toast.present();
  }
}
