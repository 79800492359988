import fecha from 'fecha';

import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Subscription, combineLatest } from 'rxjs';

import { UnifiedOrderFood, UnifiedOrder, UnifiedOrderContext, UnifiedOrderStatusCode, UnifiedOrderDeliveryType } from '../../core/schema';
import { UnifiedOrderService } from '../../core/unified-order.service';
import { ShoppingCartService } from '../../core/shopping-cart.service';
import { UnifiedMenuService } from '../../core/unified-menu.service';
import { KiccCardOrder, KiccResult, KiccPayType, KiccResponseMap } from '../../core/schema-kicc';
import { KiccOrderService } from '../../core/kicc-order.service';
import { ToastNoticeService } from '../../shared/toast-notice/toast-notice.service';
import { AlertNoticeService } from '../../shared/alert-notice/alert-notice.service';
import { LoadingService } from '../../shared/loading/loading.service';
import { LocalStorageService } from '../../shared/local-storage/local-storage.service';
import { environment } from '../../../environments/environment';
import { get_request_auth, get_confirm_auth } from '../../core/sms-api';
import { LogService } from '../../core/log.service';
import { ModalService } from '../../shared/modal/modal.service';
import { AugmentedAddress } from '../../core/schema-message';
import { SiteKRMap, UserAddress, OrderStep } from '../../core/schema';
import { GtagService } from '../../core/gtag.service';
import { SafePipe } from 'src/app/shared/safe.pipe';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ConfService } from 'src/app/core/conf.service';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss'],
  providers: [ SafePipe ]
})
export class OrderFormComponent implements OnInit, OnDestroy, AfterViewInit {
  // KICC결제 모듈을 사용하기 위해 구성한 php서버의 페이지 Iframe
  @ViewChild('Iframe', { static: false }) IframeRef: ElementRef;
  iframeDomain: string;
  iframeUrl: SafeResourceUrl;
  kiccOrder: KiccCardOrder;
  kiccResult: KiccResult;
  spMallId = 'T0010131';
  spPayType: KiccPayType = '11';

  // 문자 인증
  sessionId: string;
  confirmAuth = false;

  orderForm: FormGroup;
  isOrderAvail: 'Y' | 'N' = 'Y';

  currentSite: string;
  shopNo: string;
  unifiedOrderFoods: UnifiedOrderFood[] = [];

  // UI 표시용
  deliveryType: UnifiedOrderDeliveryType;
  siteKR: string;
  step: OrderStep = 'order';
  totalQty = 0;
  totalAmount = 0;
  availableLocalStorage = true;
  useLocalStorage = true;
  userStateLoaded = false;

  // Data model
  docRefId = '';
  unifiedOrder: Partial<UnifiedOrder> = {};
  unifiedOrderContext: Partial<UnifiedOrderContext> = {};

  private combineLatestSubscription: Subscription = null;
  private shoppingCartsubscription: Subscription = null;

  constructor(
    private shoppingCartService: ShoppingCartService,
    private confService: ConfService,
    private logService: LogService,
    private unifiedMenuService: UnifiedMenuService,
    private unifiedOrderService: UnifiedOrderService,
    private toastNoticeService: ToastNoticeService,
    private kiccOrderService: KiccOrderService,
    private loadingService: LoadingService,
    private localStorageService: LocalStorageService,
    private alertNoticeService: AlertNoticeService,
    private modalService: ModalService,
    private gtagService: GtagService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private safePipe: SafePipe,
    private fb: FormBuilder,
  ) {
      this.spMallId = environment.kiccMallId;
      this.iframeDomain = environment.paymentServer;
  }

  ngOnInit() {
    this.initForIframe();
    this.getDataFromRoute();

    this.observe();
    this.observeShoppingCart();

    this.initModel();
    this.initKiccOrderForm();
    this.buildForm();
    this.observeUserTel();

    this.availableLocalStorage = this.localStorageService.checkAvailable();
    this.useLocalStorage = this.availableLocalStorage;
    this.loadLocalUserState();
  }

  getDataFromRoute() {
    const state: any = this.location.getState();

    this.deliveryType = state.deliveryType;
    this.shopNo = this.shoppingCartService.shopNo;
    this.currentSite = this.route.snapshot.paramMap.get('site');
  }

  ngOnDestroy() {
    if (this.combineLatestSubscription) {
      this.combineLatestSubscription.unsubscribe();
    }
    if (this.shoppingCartsubscription) {
      this.shoppingCartsubscription.unsubscribe();
    }

    const key = 'ref';
    window[key] = undefined;
  }

  /******************************************************************
   * [Iframe handling]
   ******************************************************************/
  initForIframe() {
    // 1. dev.kicc.ghostaurant.co의 SOP문제를 피하기 위해 도메인을 상위로 맞춤.
    document.domain = 'ghostaurant.co';
    this.iframeUrl = this.safePipe.transform(this.iframeDomain + '/mobile/mobile/order.php');

    // 2. Child Dom에서 부모의 Function에 접근을 위함.
    const key = 'ref';
    window[key] = { component: this };
  }
  
  // 결제 진행 상태를 확인한다. (IFrame의 page 갱신이 일어날 때 마다 step을 체크한다.)
  ngAfterViewInit() {
    this.IframeRef.nativeElement.onload = () => {
      if (this.step === 'finish') {
        this.kiccResult = this.IframeRef.nativeElement.contentWindow.getResult();
        this.finishOrder();
      }
    };
  }

  // IFrame에서 호출 - 현재의 컴포넌트(parent)에 step 정보를 전달하기 위함
  updateStep(step: OrderStep) {
    this.step = step;
  }

  // IFrame에서 호출 - 결제창에서 정상결제가 아닌 응답의 경우 Alert를 띄우기 위함
  kiccAlert(code: string, msg: string) {
    const msgForCode = KiccResponseMap.get(code);
    this.alertNoticeService.noticeAlert(`${msgForCode ? msgForCode : msg}`);
  }

  // IFrame에서 호출
  async presentLoading() {
    await this.loadingService.presentLoading();
  }

  // IFrame에서 호출
  async dismissLoading() {
    await this.loadingService.dismissLoading();
  }

  /******************************************************************
   * [Observing]
   ******************************************************************/
  private observe() {
    const organization = 'ghostkitchen';
    const unifiedMenu = this.unifiedMenuService.latestUnifiedMenuForShopSubject;
    const organizationConf = this.confService.latestOrganizationConfSubject;

    this.combineLatestSubscription = combineLatest([unifiedMenu, organizationConf]).subscribe(([unifiedMenu, conf]) => {
      const confRoom = conf[organization].site[this.currentSite].room[unifiedMenu.room];
      const isLive = (confRoom.live && !confRoom.virtual);

      this.isOrderAvail = isLive ? unifiedMenu.baeminShopInfo.Ord_Avail_Yn : 'N';
    });
  }

  private observeShoppingCart() {
    this.shoppingCartsubscription = this.shoppingCartService.latestUnifiedOrderFoodsSubject.subscribe(unifiedOrderFoods => {
      this.unifiedOrderFoods = unifiedOrderFoods;

      this.totalAmount = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodOrdPrice, 0);
      this.totalQty = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodQty, 0);
    });
  }

  /******************************************************************
   * [Modeling]
   ******************************************************************/
  initModel() {
    // Data model
    this.unifiedOrder = {...this.unifiedOrder, ...{
      orderChannel: 'app',
      orderVendor: 'ghostkitchen',
      deliveryType: this.deliveryType,
      instanceNo: '',
      orderNo: '',
      shopNo: '',
      orderDate: fecha.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+0900'), // 나중에 최종 적으로 업데이트한다.
      orderStatusCode: UnifiedOrderStatusCode.NEW,
      orderAmount: 0,
      deliveryTip: 0,
      deliveryMinutes: 0,
      paymentMethod: '선불',
      userTel: '',
      orderMsg: '',

      address_key: '',
      address_detail: '',
      address_sido: '',
      address_sigungu: '',
      address_dong: '',
      address_jibun: '',
      address_dongH: '',
      address_road: '',
      address_building_name: '',
      address_location: {
        lon: 0,
        lat: 0
      },
      vroong: {
        dest_sigungu: '',
        dest_legal_eupmyeondong: '',
        dest_admin_eupmyeondong: '',
        dest_ri: '',
        dest_beonji: '',
        dest_road: '',
        dest_building_number: '',
      },
    }};

    this.unifiedOrderContext = {...this.unifiedOrderContext, ...{
      eventDiscount: 0,
    }};

    this.resetForShop();
  }

  initKiccOrderForm() {

    const init: KiccCardOrder = {
      /*--공통--*/
      sp_mall_id: this.spMallId,
      sp_order_no: '',
      sp_pay_type: this.spPayType,
      sp_currency: '00',
      sp_product_nm: '',
      sp_product_amt: '',
      sp_return_url: `${this.iframeDomain}/mobile/mobile/order_res_submit.php`,
      sp_user_phone1: '',
      sp_mall_nm: '고스트키친',
      sp_lang_flag: 'KOR',
      sp_charset: 'EUC-KR',
      sp_user_nm: '일반 고객',
      sp_product_type: '0',
      sp_window_type: 'submit',
      sp_product_expr: '',
      sp_user_id: '',
      sp_memb_user_no: '',
      sp_user_mail: '',
      sp_user_phone2: '',
      sp_user_addr: '',
      sp_app_scheme: '',

      /*--신용카드--*/
      sp_usedcard_code: '',
      sp_quota: '',
    };

    this.kiccOrder = init;
  }

  resetForShop() {
    const unifiedMenu = this.unifiedMenuService.unifiedMenuForShop;

    if (unifiedMenu === undefined) {
      this.toastNoticeService.noticeToast(`접근할 수 없는 주소입니다.`);
      this.goHome();
    }

    // 업소 정보를 채워준다.
    this.unifiedOrder.organization = unifiedMenu.organization;
    this.unifiedOrder.site = unifiedMenu.site;
    this.unifiedOrder.room = unifiedMenu.room;
    this.unifiedOrder.shopNo = unifiedMenu.shopNo;
    this.unifiedOrder.shopName = unifiedMenu.shopName;

    // ShopName에서 site와 중복되는 내용은 제거한다. ex) 강남역점 서울숲쭈꾸미(강남점) => 강남역점 서울숲쭈꾸미
    const roomInfo = unifiedMenu.room.split('-');
    const replaceWords = ['(강남점)', '(삼성점)', '(송파점)'];
    let shopName = unifiedMenu.shopName;
    replaceWords.forEach(word => {
      shopName = shopName.replace(word, '');
    });
    this.siteKR = `테이크아웃(${SiteKRMap.get(roomInfo[1])} ${shopName.trim()})`;
  }

  /**
   * 최초에 한 번만 실행
   */
  buildForm() {
    this.orderForm = this.fb.group({
      userTel: [{ value: this.unifiedOrder.userTel, disabled: this.confirmAuth }, this.userTelValidator()],
      address_detail: [this.unifiedOrder.address_detail, this.addressValidator()],
      orderMsg: this.unifiedOrder.orderMsg,
      authCode: '',
    }, { validators: this.formValidator() }); // cross field validation : https://angular.io/guide/form-validation#cross-field-validation
  }

  /**
   * userTel에 변화가 있으면 포맷을 자동 적용한다.
   */
  observeUserTel() {
    const formControl = this.orderForm.get('userTel');

    formControl.valueChanges.forEach(value => {
      const normalizedTel = this.normalizeTel(value);
      if (value !== normalizedTel) {
        this.orderForm.get('userTel').setValue(normalizedTel);
      }
    });
  }

  /**
   * 입력 과정 중에 자동으로 -를 붙여준다.
   * util.ts에 있는 noramlizeTel과는 쓰임이 다르다.
   */
  normalizeTel(telNo: string) {
    // 숫자 이외에는 모두 제외한다.
    telNo = telNo.replace(/[^0-9]/g, '');

    // debugLog(`1. telNo = ${telNo}`);

    if (telNo[0] !== '0') {
      return '';
    }
    // 2번째 숫자가 허용되지 않는 숫자라면 거부
    if (telNo[1] !== '1' && telNo[1] !== '2'  && telNo[1] !== '5' && telNo[1] !== '7') {
      return telNo[0];
    }
    // 010, 050, 070 이 아니고 051같은 경우는 거부
    // if ((telNo[1] === '1' || telNo[1] === '5' || telNo[1] === '7') && telNo[2] !== '0' ) {
    //   return `${telNo[0]}${telNo[1]}`;
    // }

    if (telNo.match(/^010|050|070/)) {
      // 국번이 0이나 1로 시작하지 않는다.
      if (telNo[3] === '0' || telNo[3] === '1') {
        return telNo.substr(0, 3);
      }

      if (telNo.length === 12) {
        return `${telNo.substr(0, 4)}-${telNo.substr(4, 4)}-${telNo.substr(8, 4)}`;
      } else if (telNo.length > 7) {
        return `${telNo.substr(0, 3)}-${telNo.substr(3, 4)}-${telNo.substr(7, 4)}`;
      } else if (telNo.length > 3) {
        return `${telNo.substr(0, 3)}-${telNo.substr(3, 4)}`;
      } else {
        return telNo;
      }
    } else { // 02
      // 국번이 0이나 1로 시작하지 않는다.
      if (telNo[2] === '0' || telNo[2] === '1') {
        return telNo.substr(0, 2);
      }

      if (telNo.length > 9) {
        return `${telNo.substr(0, 2)}-${telNo.substr(2, 4)}-${telNo.substr(6, 4)}`;
      } else if (telNo.length > 5) {
        return `${telNo.substr(0, 2)}-${telNo.substr(2, 3)}-${telNo.substr(5, 4)}`;
      } else if (telNo.length > 2) {
        return `${telNo.substr(0, 2)}-${telNo.substr(2, 3)}`;
      } else {
        return telNo;
      }
    }
  }

  /**
   * deliveryType의 값에 따라서 결과가 다른다.
   */
  userTelValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      // this.orderForm은 this가 변할 경우에 undefined가 되는 경우가 있다.
      const userTel = control.value;

      if (userTel && userTel.length > 0) {
        const match = userTel.match(/^(0[157]0[1-9]?-[1-9][0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/);
        if (match === null) {
          return { reason: '전화번호가 형식에 맞지 않습니다.'};
        }
      } else {
        return { reason: '전화번호가 필요합니다.'};
      }
    };
  }

  addressValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const addressDetail = control.value;

      if (this.deliveryType === 'DELIVERY') {
        if (!addressDetail || addressDetail.length < 1) {
          return { reason: '상세주소가 필요합니다.' };
        }
      }
    };
  }

  /**
   * 모든 control이 변경될 때마다 호출된다.
   * userTel과 deliveryType의 변경때 마다 userTel의 validator를 실행시키기 위해 사용한다.
   */
  formValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {

      const orderForm = control;
      if (orderForm) {
        // onlySelf를 false로 하면 무한반복한다.
        orderForm.get('userTel').updateValueAndValidity({onlySelf: true});
      }

      return null;
    };
  }

  /**
   * UI 내용을 unifiedOrder에 반영
   */
  updateModel() {
    this.unifiedOrder = {...this.unifiedOrder, ...{
      userTel: (this.orderForm.get('userTel').value).replace(/-/g, ''),
      address_detail: this.orderForm.get('address_detail').value,
      orderMsg: this.orderForm.get('orderMsg').value,
    }};
  }

  handleSelect(event: any) {
    this.spPayType = event.target.value;
  }

  
  /******************************************************************
   * [KICC 결제]
   * 1. order()                       결제 인증 요청 - kiccCardOrderDoc 기록
   *    - deferredPayOrder()          후불결제
   *    - advancePayOrder()           선불결제
   *      - fillRequestForPayment()   주문서 작성
   * 3. finishOrder()                 주문 - UnifiedOrderDoc 기록
   ******************************************************************/
  async order() {
    // 주문전에 마지막으로 업소상태를 확인한다.
    if (this.isOrderAvail === 'N') {
      this.alertNoticeService.noticeAlert('죄송합니다. 준비중인 업소입니다.');
      this.shoppingCartService.emptyCart();
      return this.goHome();
    }

    this.updateModel();

    // 주소와 전화번호 LocalStorage에 저장
    if (this.useLocalStorage) {
      this.saveLocalUserState();
    }

    // 후불과 선불결제를 구분한다.
    if (Number(this.spPayType) < 11) {
      this.deferredPayOrder();
    } else {
      this.advancePayOrder();
    }
  }

  /**
   * 후불결제
   */
  async deferredPayOrder() {
    this.fillRequestForPayment('deferred');

    await this.loadingService.presentLoading();
    await this.finishOrder();
    this.gtagService.page('/buy');

    await this.loadingService.dismissLoading();
    this.alertNoticeService.noticeAlertConfirm('잠시 후 접수가 완료되면<br>예상 배달 시간을<br>문자로 알려드립니다.', () => this.goHome(), false);
  }

  /**
   * 선불결제
   */
  async advancePayOrder() {
    this.fillRequestForPayment('advance');
    
    await this.loadingService.presentLoading();
    try {
      // 필수 항목 누락 여부 확인
      if (!this.kiccOrder.sp_order_no) {
        this.loadingService.dismissLoading();
        this.alertNoticeService.noticeAlert(`주문 실패 : 가맹점 주문번호가 비어있습니다.`);
        return;
      } else if (Number(this.kiccOrder.sp_product_amt) <= 0) {
        this.loadingService.dismissLoading();
        this.alertNoticeService.noticeAlert(`주문 실패 : 결제할 금액이 없습니다.`);
        return;
      }
      // 1. 결제 인증내용(kiccOrder) 기록
      await this.kiccOrderService.createKiccOrder(this.kiccOrder, this.unifiedOrder);

      // 2. Iframe으로 결제내용 전송
      this.IframeRef.nativeElement.contentWindow.submitOrder(this.kiccOrder);
      this.loadingService.dismissLoading();
    } catch (error) {
      await this.loadingService.dismissLoading();
      this.toastNoticeService.noticeToast(error);
    }
  }

  fillRequestForPayment(paymentMethod: 'advance' | 'deferred') {
    this.unifiedOrder.foods = this.unifiedOrderFoods;
    this.unifiedOrder.orderAmount = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodOrdPrice, 0);
    this.unifiedOrder.orderDate = fecha.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+0900');

    if (paymentMethod === 'advance') {
      this.unifiedOrder.paymentMethod = '선불';

      let spProductNm = this.unifiedOrder.foods[0].mergedName;
      if (this.unifiedOrder.foods.length > 1) {
        spProductNm += `외 ${this.unifiedOrder.foods.length - 1}개`;
      }
      this.docRefId = this.unifiedOrderService.getFingerDocId();
      this.kiccOrder.sp_order_no = this.docRefId;
      this.kiccOrder.sp_user_phone1 = this.unifiedOrder.userTel;
      this.kiccOrder.sp_user_addr = this.unifiedOrder.address_detail;
      this.kiccOrder.sp_mall_nm = this.unifiedOrder.shopName;
      this.kiccOrder.sp_pay_type = this.spPayType;
      this.kiccOrder.sp_product_nm = spProductNm;
      this.kiccOrder.sp_product_amt = String(this.unifiedOrder.orderAmount);
    }

    if (paymentMethod === 'deferred') {
      this.unifiedOrder.paymentMethod = this.spPayType === '01' ? '후불카드' : '후불현금';
    }
  }

  async finishOrder() {
    try {
      // 3. 주문내용(unifiedOrder) 기록
      await this.unifiedOrderService.createFingerOrder(this.unifiedOrder, this.unifiedOrderContext, this.docRefId);
      // 정상처리시 쇼핑카트를 비워준다.
      this.shoppingCartService.emptyCart();
    } catch (error) {
      this.toastNoticeService.noticeToast(error);
    }
  }


  /******************************************************************
   * [전화번호 문자인증]
   * 1. sendAuthSMS()     인증 문자 발송
   * 2. confirmSMSCode()  인증 문자 확인
   ******************************************************************/
  async sendAuthSMS() {
    const userTel = this.orderForm.get('userTel').value;

    try {
      await this.loadingService.presentLoading();
      this.gtagService.page('/req-auth');
      const response = await get_request_auth(userTel);

      const { sessionId } = await response.json() as { sessionId: string };
      this.sessionId = sessionId;
      this.logService.info(`문자전송 성공 ${userTel}`);
    } catch (error) {
      await this.toastNoticeService.noticeToast(error);
      this.logService.error(`문자전송 실패 ${userTel} : ${error}`);
    } finally {
      await this.loadingService.dismissLoading();
    }
  }

  async confirmSMSCode() {
    const authCode = this.orderForm.get('authCode').value;
    const { userTel } = this.unifiedOrder;

    try {
      this.loadingService.presentLoading();
      this.gtagService.page('/confirm-auth');
      const response = await get_confirm_auth(this.sessionId, authCode);

      const { result, reason } = await response.json() as { result: string, reason: string| null };
      this.confirmAuth = (result === 'success');

      if (reason !== null) {
        const msg = (reason === 'autoCode mismatch') ? '잘못된 인증문자입니다.<br>다시 입력해주세요.' : reason;
        await this.toastNoticeService.noticeToast(msg);
        this.logService.error(`문자인증 실패 ${userTel} : ${reason}`);
      } else {
        this.logService.info(`문자인증 성공 ${userTel}`);
      }

    } catch (error) {
      await this.logService.error(`문자인증 실패 ${userTel} : ${error}`);
      this.toastNoticeService.noticeToast(error);
    } finally {
      await this.loadingService.dismissLoading();
    }
  }

  /******************************************************************
   * [주소검색]
   ******************************************************************/
  updateAddress(response: AugmentedAddress) {
    this.initModel();

    this.unifiedOrder = {...this.unifiedOrder, ... {
      address_key: response.key,
      address_dongH: response.dongH,
      address_road: response.road,
      address_building_name: response.building_name,
      address_location: response.location,
      address_sido: response.sido,
      address_sigungu: response.sigungu,
      address_dong: response.dong,
      address_jibun: response.jibun,
      vroong: response.vroong,
    }};
  }

  async presentAddressModal() {
    try {
      this.gtagService.page('/search-address');
      await this.modalService.presentAddressModal( (response) => this.updateAddress(response) );
    } catch (error) {
      await this.logService.error(`주소 찾기 실패 : ${error}`);
    }
  }

  /******************************************************************
   * [LocalStorage]
   ******************************************************************/
  onCheckboxChange(event) {
    this.useLocalStorage = event.target.checked;
  }

  saveLocalUserState() {
    const unifiedOrder = this.unifiedOrder;
    try {
      this.localStorageService.setItem('userTel', this.unifiedOrder.userTel);
      if (this.deliveryType === 'DELIVERY') {
        this.localStorageService.setItem('userAddress', {
          address_key: unifiedOrder.address_key,
          address_dongH: unifiedOrder.address_dongH,
          address_road: unifiedOrder.address_road,
          address_building_name: unifiedOrder.address_building_name,
          address_location: unifiedOrder.address_location,
          address_sido: unifiedOrder.address_sido,
          address_sigungu: unifiedOrder.address_sigungu,
          address_dong: unifiedOrder.address_dong,
          address_jibun: unifiedOrder.address_jibun,
          address_detail: this.unifiedOrder.address_detail,
          vroong: unifiedOrder.vroong,
        });
      }
    } catch (error) {
      this.toastNoticeService.noticeToast(`주문정보 저장 실패 : ${error}`);
    }

    this.orderForm.get('userTel').disable();
    this.orderForm.get('address_detail').disable();
  }

  loadLocalUserState() {
    if (this.localStorageService.isExist('userTel')) {
      try {
        const userTel = this.localStorageService.getValue('userTel') as string;
        this.orderForm.get('userTel').setValue(userTel);
        this.confirmAuth = true;

        if (this.deliveryType !== 'TAKEOUT' && this.localStorageService.isExist('userAddress')) {
          const userAddress = this.localStorageService.getValue('userAddress') as UserAddress;
          this.orderForm.get('address_detail').setValue(userAddress.address_detail);
          this.orderForm.get('address_detail').disable();
          this.unifiedOrder = { ...this.unifiedOrder, ...userAddress };
        }

        this.userStateLoaded = true;
      } catch (error) {
        this.toastNoticeService.noticeToast(`주문정보 불러오기 실패 : ${error}`);
      }
    }
  }

  changeUserState() {
    this.confirmAuth = false;
    this.userStateLoaded = false;
    this.orderForm.get('address_detail').enable();
  }

  /******************************************************************
   * [Routing]
   ******************************************************************/
  goHome() {
    this.router.navigate([this.currentSite], { replaceUrl: true });
  }
}
