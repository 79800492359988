//////////////////////////////////////////////////////////////////////////////
//
// kicc
//


export type KiccPayType =
    '01' // 01:후불현금
  | '02' // 02:후불카드
  | '11' // 11:신용카드
  | '21' // 21:계좌이체
  | '22' // 22:가상계좌
  | '31' // 31:휴대폰
  | '50' // 50:선불결제
  | '60' // 60:간편결제
  | '81'; // 81:배치인증

export type KiccCurrencyCode = '00'; // '00' : 원화

// 신용카드만 적용
export type KiccLangCode =
    'KOR' // 'KOR' : 한국어
  | 'ENG' // 'ENG' : 영어
  | 'CHN' // 'CHN' : 중국어
  | 'JPN'; // 'JPN' : 일본어

export type KiccCharset = 'EUC-KR' | 'UTF-8';

// '1' : 일반
// '2' : 회원
export type KiccUserType = '1' | '2';

// '0' : 실물
// '1' : 서비스
export type KiccProductType = '0' | '1';

// 제휴 서비스 코드
export type KiccJoinCode =
    'JC01' // 카자니아 삼성제휴
  | 'JC02' // 현대카드 M포인트 청구할인
  | 'JC03' // 국민카드 아이사랑 카드할인
  | 'JC04' // ARS 신용카드 결제 거래 Online 거래
  | 'JC05' // ARS 신용카드 결제 거래 Offline 거래
  | 'JC06' // 세이브 결제 거래
  | 'JC07' // 비씨 물품바우처 승인 거래
  | 'JC08' // 롯데 청구할인
  | 'JC09' // 이브릿지 발렛파킹
  | 'JC10'; // 카자니아 BC제휴

// 거래 상태 코드
export type KiccStatCode =
    'RF01' // 환불요청
  | 'RF02' // 환불완료
  | 'RF03' // 환불거절
  | 'TS01' // 승인
  | 'TS02' // 승인취소
  | 'TS03' // 매입요청
  | 'TS04' // 매입
  | 'TS05' // 매입취소
  | 'TS06' // 부분매입(승인)취소
  | 'TS07' // 입금대기
  | 'TS08'; // 입금완료

// 은행 코드 - 가상계좌(채번) / (계좌이체/환불요청)
export type KiccVacctBank =
    '002' // 산업은행
  | '003' // 기업은행
  | '004' // 국민은행
  | '005' // 외환은행
  | '007' // 수협
  | '011' // 농협중앙회
  | '012' // 단위농협
  | '020' // 우리은행
  | '023' // SC제일은행
  | '026' // 신한은행
  | '027' // 한국씨티은행
  | '031' // 대구은행
  | '032' // 부산은행
  | '034' // 광주은행
  | '035' // 제주은행
  | '037' // 전북은행
  | '039' // 경남은행
  | '045' // 새마을금고
  | '048' // 신협
  | '050' // 상호저축은행
  | '071' // 우체국
  | '081' // 하나은행
  | '088' // 신한은행
  | '089' // K뱅크
  | '090' // 카카오뱅크
  | '209' // 동양종금증권
  | '218' // 현대증권
  | '230' // 미래에셋
  | '238' // 대우증권
  | '240' // 삼성증권
  | '243' // 한국투자증권
  | '247' // 우리투자증권
  | '262' // 하이증권
  | '263' // HMC증권
  | '266' // SK증권
  | '267' // 대신증권
  | '270' // 하나대투증권
  | '278' // 신한금융투자
  | '279' // 동부증권
  | '280' // 유진증권
  | '287' // 메리츠증권
  | '291'; // 신영증권

// 신용카드 인증창 호출시 제어코드
export type KiccUsedCardCode =
    '002' // 광주은행
  | '008' // 외환카드
  | '011' // 제주은행
  | '017' // 수협
  | '021' // 우리카드
  | '026' // 비씨카드
  | '028' // JCB
  | '031' // 삼성카드
  | '047' // 롯데카드
  | '049' // 마스터
  | '058' // 산업은행
  | '126' // 저축은행
  | '326' // 새마을금고
  | '526' // 하나BC카드
  | '726' // 현대증권
  | '926' // 유진투자증권
  | 'A26' // IBK기업
  | 'C26' // K뱅크
  | 'E26' // 대구은행
  | 'G26' // SC제일은행
  | '006' // 하나카드
  | '010' // 전북은행
  | '016' // 국민카드
  | '018' // NH농협
  | '022' // 씨티카드
  | '027' // 현대카드
  | '029' // 신한카드
  | '046' // 아멕스
  | '048' // 다이너스
  | '050' // 비자
  | '081' // 은련카드
  | '226' // 우체국
  | '426' // 중국은행
  | '626' // 신협
  | '826' // 교보증권
  | 'A16' // 카카오뱅크
  | 'B26' // SK증권
  | 'D26' // NH투자증권
  | 'F26' // 부산은행
  | 'H26'; // 경남은행

// 주문내용
interface KiccCommonOrder {
  // 필수
  sp_mall_id: string; // 가맹점 ID _ KICC에서 발급하는 가맹점 구분값
  sp_order_no: string;  // 가맹점 주문번호 _ 주문에 대한 유일한 값
  sp_pay_type: KiccPayType;  // 결제수단
  sp_currency: KiccCurrencyCode;  // 통화코드
  sp_product_nm: string;  // 상품명 _ 상품의 정보를 입력(40byte이내)
  sp_product_amt: string; // 상품금액 _ 21억 이하
  sp_return_url: string;  // 가맹점 return_url _ 인증응답 받을 가맹점 URL

  // 선택
  sp_mall_nm?: string; // 가맹점 명 _ 인증창에 표시할 가맹점 명
  sp_lang_flag?: KiccLangCode;  // 언어 _ KOR(default)
  sp_charset?: KiccCharset; // 가맹점 Charset _ EUC-KR(default)
  sp_user_phone1?: string;  // 가맹점 고객 연락처('_'없이 입력)
  sp_product_type?: KiccProductType;  // 상품정보구분 : 0-실물; 1-서비스 _ 0(default)
  sp_window_type?: string;  // 윈도우 타입 _ iframe : layer popup, 그외 : submit(default)

  sp_user_nm?: string; // 가맹점 고객명
  sp_product_expr?: string; // 서비스기간 : YYYYMMDD
  sp_user_id?: string; // 가맹점 고객 ID
  sp_user_type?: KiccUserType;  // 사용자 구분 : 1-일반; 2-회원
  sp_memb_user_no?: string;  // 가맹점 고객 일련번호
  sp_user_mail?: string;  // 가맹점 고객 이메일
  sp_user_phone2?: string;  // 가맹점 고객 연락처('_'없이 입력)
  sp_user_addr?: string; // 가맹점 고객 주소
  sp_disp_cash_yn?: string; // 현금영수증 입력필드 노출 _ N, Y(계좌이체, 가상계좌, 휴대폰만 적용)

  // App서비스시 필수
  sp_app_scheme?: string; // 가맹점 app scheme : 모바일web이 아닌 app으로 서비스시 필수
}

/** 신용카드 */
export interface KiccCardOrder extends KiccCommonOrder {
  sp_usedcard_code?: string;  // 사용가능한 카드 LIST
                              // FORMAT->카드코드:카드코드: ... :
                              // 카드코드EXAMPLE->029:027:031
                              // 가맹점에서 노출할 카드만 취사 선택
                              // 빈값:DB조회
  sp_quota?: string;          // 할부개월
                              // FORMAT->할부개월:할부개월:...:할부개월
                              // EXAMPLE->00:03:06:...:10
                              // 00:일시불, 나머지:해당 할부개월
                              // 노출할 할부개월 선택
                              // 빈값:DB조회

  sp_os_cert_flag?: string; // 해외안심클릭 사용여부
  sp_noinst_flag?: string; // 무이자 여부(Y/N) (select) : Y:무이자, N:일반, 빈값:DB조회
  sp_noinst_term?: string; // 무이자기간
  sp_set_point_card_yn?: string; // 카드사포인트 사용여부(select) : Y:사용,그외:미사용
  sp_point_card?: string;  // 포인트카드 LIST
  sp_join_cd?: KiccJoinCode;  // 조인코드(select)
  sp_kmotion_useyn?: string;  // 국민 앱카드 사용(select) : Y:사용, N:미사용, 빈 값: DB조회
}

/** 가상계좌 */
export interface KiccVacctOrder extends KiccCommonOrder {
  sp_vacct_bank?: KiccVacctBank;  // 가상계좌 사용가능한 은행 LIST
  sp_vacct_end_date?: string;  // 입금 만료 날짜
  sp_vacct_end_time?: string;  // 입금 만료 시간
}

/** 선불카드 */
export interface KiccPrepaidOrder extends KiccCommonOrder {
  sp_prepaid_cp?: string;  // 선불카드 CP
}

/** 인증응답 */
interface KiccCertifiedOrderResponse {
  sp_res_cd: string;  // 응답코드 _ '0000': 정상, 나머지: 에러
  sp_res_msg: string; // 응답메세지
  sp_tr_cd: string; // 결제창 요청구분
  sp_ret_pay_type: string;  // 결제수단
  sp_card_code: string; // "카드코드 (ISP:KVP카드코드 MPI:카드코드)"
  sp_card_req_type: string; // 거래구분
  sp_trace_no: string;  // 추적번호
  sp_order_no: string;  // 가맹점 주문번호
  sp_sessionkey: string;  // 세션키
  sp_encrypt_data: string;  // 암호화전문
  sp_spay_cp: string; // 간편결제 CP 코드
  sp_mall_id: string; // 가맹점ID
  sp_product_amt: string; // 거래금액 (21억 이하)

  sp_prepaid_cp?: string;  // 선불카드 CP
  sp_card_prefix?: string; // 신용카드prefix
  sp_eci_code?: string;  // MPI인 경우 ECI코드
  sp_save_useyn?: string;  // 카드사 세이브 여부 (Y/N)
}

/** 결제승인결과 */
export interface KiccResult {
  res_cd: string; // 결과코드
  res_msg: string;  // 결과메세지
  cno: string;  // PG거래번호
  amount: string; // 총 결제금액
  order_no: string; // 주문번호
  auth_no: string;  // 승인번호
  tran_date: string;  // 승인일시
  escrow_yn: string;  // 에스크로여부
  complex_yn: string; // 복합결제여부
  stat_cd: KiccStatCode;  // 상태코드
  stat_msg: string; // 상태메시지
  pay_type: string; // 결제수단
  card_no: string;  // 카드번호
  issuer_cd: string;  // 발급사 코드
  issuer_nm: string;  // 발급사 명
  acquirer_cd: string;  // 매입사 코드
  acquirer_nm: string;  // 매입사 명
  install_period: string; // 할부개월
  noint: string;  // 무이자여부
  part_cancel_yn: string; // 부분취소 가능여부
  card_gubun: string; // 신용카드 구분
  card_biz_gubun: string; // 은행코드
  cpon_flag: string;  // 쿠폰 사용유무
  bank_cd: string;  // 은행코드
  bank_nm: string;  // 은행명
  account_no: string; // 계좌번호
  deposit_nm: string; // 입금자명
  expire_date: string;  // 계좌사용만료일
  cash_res_cd: string;  // 현금영수증 결과코드
  cash_res_msg: string; // 현금연수증 결과메시지
  cash_auth_no: string; // 현금영수증 승인번호
  cash_tran_date: string; // 현금영수증 승인일시
  cash_issue_type: string;  // 현금영수증 발행용도
  cash_auth_type: string; // 현금영수증 인증구분
  cash_auth_value: string;  // 현금영수증 인증번호
  auth_id: string;  // 휴대폰 ID
  billid: string; // 휴대폰 인증번호
  mobile_no: string;  // 휴대폰번호
  mob_ansim_yn: string; // 안심결제 사용유무
  cp_cd: string;  // 포인트사/쿠폰사
  rem_amt: string;  // 잔액
  bk_pay_yn: string;  // 장바구니 결제여부
  canc_acq_date: string;  // 매입취소일시
  canc_date: string;  // 취소일시
  refund_date: string;  // 환불예정일시
}

export const PayTypeMap = new Map<string, string>([
  ['11', '신용카드'],
  ['22', '가상계좌'],
  ['31', '휴대폰'],
  ['50', '선불결제'],
  ['60', '간편결제'],
  ['81', '배차인증']
]);

export const KiccResponseMap = new Map<string, string>([
  ['0000', '정상'],
  ['0200', '인증실패'], // 금융결제원 오류
  ['W001', '비정상 접속'],
  ['W002', '결제를 취소하셨습니다.'], // [USER] 사용자 취소
  ['W101', '[MALL]가맹점ID가 비었습니다.'],
  ['W102', '[MALL]가맹점명이 비었습니다.'],
  ['W103', '[MALL]결제수단이 비었습니다.'],
  ['W104', '[MALL]통화코드가 비었습니다.'],
  ['W105', '[MALL]가맹점 주문번호가 비었습니다.'],
  ['W106', '[MALL]고객휴대폰번호가 비었습니다.'],
  ['W107', '[MALL]카드코드가 비었습니다.'],
  ['W108', '[MALL]할부개월이 비었습니다.'],
  ['W109', '[MALL]유효기간이 비었습니다.'],
  ['W110', '[MALL]상품명이 비었습니다.'],
  ['W111', '[MALL]상품금액이 비었습니다.'],
  ['W112', '[MALL]포인트 설정값이 비었습니다.'],
  ['W113', '[MALL]무이자 설정값이 비었습니다.'],
  ['W114', '[MALL]가맹점 return url이 비었습니다.'],
  ['W115', '[MALL]에스크로 구매자명이 비었습니다.'],
  ['W116', '[MALL]에스크로 구매자휴대폰번호가 비었습니다.'],
  ['W117', '[MALL]에스크로 구매자이메일이 비었습니다.'],
  ['W118', '[MALL]에스크로 장바구니 총금액이 비었습니다.'],
  ['W119', '[MALL]에스크로 장바구니 건수가 비었습니다.'],
  ['W120', '[MALL]에스크로 장바구니 정보가 비었습니다.'],
  ['W121', '[MALL]카드 Prefix 정보가 비었습니다.'],
  ['W122', '[MALL]추적번호 정보가 비었습니다.'],
  ['W123', '[MALL]카드번호가 비었습니다.'],
  ['W140', '[MALL]상품금액이 숫자가 아닙니다.'],
  ['W141', '[MALL]할부개월이 숫자가 아닙니다.'],
  ['W142', '[MALL]에스크로 장바구니 총금액이 숫자가 아닙니다.'],
  ['W143', '[MALL]에스크로 장바구니 건수 값이 숫자가 아닙니다.'],
  ['W150', '[MALL]결제수단 값이 잘못되었습니다.'],
  ['W151', '[MALL]서비스기간 값이 잘못되었습니다.'],
  ['W152', '[MALL]입금만료날짜 값이 잘못되었습니다.'],
  ['W153', '[MALL]입금만료시간 값이 잘못되었습니다.'],
  ['W154', '[MALL]장바구니 정보 값이 잘못되었습니다.'],
  ['W155', '[MALL]카드코드 값이 잘못되었습니다.'],
  ['W156', '[MALL]유효기간 값이 잘못되었습니다.'],
  ['W157', '[MALL]고객 휴대폰 값이 잘못되었습니다.'],
  ['W158', '[MALL]할부개월 값이 잘못되었습니다. (금액 50000원 이상)'],
  ['W159', '[MALL]국내카드를 이용하시고 잇습니다. 안심클릭이나 ISP로 재시도 해주시기 바랍니다.'],
  ['W170', '[MALL]가맹점ID 길이가 잘못되었습니다. (길이:8)'],
  ['W171', '[MALL]결제수단 길이가 잘못되었습니다. (길이:2)'],
  ['W172', '[MALL]가맹점명 길이가 잘못되었습니다. (최소:2자 이상)'],
  ['W200', '[KICC]DB처리 오류. 잠시후 재시도 요망'],
  ['W201', '[KICC]DB조회 실패. 입력하신 데이터를 확인해 주시기 바랍니다.'],
  ['W210', '[KICC]가맹점 정보가 없습니다.'],
  ['W211', '[KICC]등록된 결제수단이 없습니다.'],
  ['W212', '[KICC]등록된 계좌이체 서비스 정보가 없습니다.'],
  ['W213', '[KICC]등록된 휴대폰 서비스 정보가 없습니다.'],
  ['W214', '[KICC]등록된 선불카드 서비스 정보가 없습니다.'],
  ['W215', '[KICC]등록된 페이핀 서비스 정보가 없습니다.'],
  ['W216', '[KICC]페이핀 인증내역이 없습니다.'],
  ['W217', '[KICC]쿠폰 정보가 없습니다.'],
  ['W230', '[KICC]가맹점 Status가 정상이 아닙니다.'],
  ['W231', '[KICC]가맹점 승인허가상태가 정상이 아닙니다.'],
  ['W240', '[KICC]추적번호가 생성되지 않았습니다.'],
  ['W250', '[KICC]요청정보를 저장하지 못했습니다. KICC로 문의바랍니다.'],
  ['W251', '[KICC]인증정보를 저장하지 못했습니다. KICC로 문의바랍니다.'],
  ['W252', '[KICC]인증정보를 저장하지 못했습니다. KICC로 문의바랍니다.'],
  ['W253', '[KICC]결과코드를 저장하지 못했습니다. KICC로 문의바랍니다.'],
  ['W254', '[KICC]휴대폰인증정보를 저장하지 못했습니다. KICC로 문의바랍니다.'],
  ['W255', '[KICC]인증기관 거래번호를 저장하지 못했습니다. KICC로 문의바랍니다.'],
  ['W256', '[KICC]쿠폰정보를 저장하지 못했습니다. KICC로 문의바랍니다.'],
  ['W270', '[KICC]에스크로 가맹점이 아닙니다.'],
  ['W301', '[ISP]안전결제 인증에 실패하였거나 취소를 하였습니다.'],
  ['W302', '[ISP]가맹점에서 사용불가능한 카드입니다.'],
  ['W303', '[ISP]VP에서 인증거래 요청에 대한 응답이 없습니다.'],
  ['W304', '[ISP]VP로 인증거래 요청 실패 하였습니다.'],
  ['W305', '[ISP]VP로 인증거래 요청 실패 하였습니다.'],
  ['W311', '[V3D]요청파라메터 없음.'],
  ['W312', '[V3D]가맹점에서 사용불가능한 카드입니다.'],
  ['W313', '[V3D]해당 카드 미등록 가맹점입니다.'],
  ['W314', '[V3D]하위 사업자번호가 없습니다.'],
  ['W321', '[XMPI]XMPI가 정상적으로 설치되지 않았습니다.'],
  ['W322', '[XMPI]안심클릭 대상 카드가 아닙니다.'],
  ['W323', '[XMPI]암호화 오류발생'],
  ['W324', '[XMPI]인증에 실패하였거나 취소를 하였습니다.'],
  ['W325', '[XMPI]카드사에서 응답값을 주지 않았습니다.[r0=null]'],
  ['W331', '[MPI]카드사에서 응답값을 주지 않았습니다.[pares=null]'],
  ['W332', '[MPI]카드사에서 응답값을 주지 않았습니다.[md=null]'],
  ['W341', '[SMPI]SMPI가 정상적으로 설치되지 않았습니다.'],
  ['W342', '[SMPI]안심클릭 대상 카드가 아닙니다.'],
  ['W343', '[SMPI]암호화 오류발생'],
  ['W344', '[SMPI]인증에 실패하였거나 취소를 하였습니다.'],
  ['W345', '[SMPI]카드사에서 응답값을 주지 않았습니다.[r0=null]'],
  ['W351', '[OSMPI]카드사에서 응답값을 주지 않았습니다.[pares=null]'],
  ['W352', '[OSMPI]카드사에서 응답값을 주지 않았습니다.[md=null]'],
  ['W361', '[KMOTION]가맹점에서 사용불가능한 카드입니다.'],
  ['W401', '[선불]선불카드 인증에 실패하였습니다.'],
  ['W501', '[쿠폰]쿠폰 발행에 실패하였습니다.'],
  ['W601', '[KICC]전화요망(02-3416-2900)'],
  // KMOTION 오류
  ['K100', '[KMOTION]인증 취소를 하였습니다.'],
  ['K101', '[KMOTION]거래번호가 일치하지 않습니다.'],
  ['1001', '[KMOTION]팝업 차단 설정 해제 후 다시 결제를 해 주십시오.'],
  ['2001', '[KMOTION]인증 데이터 처리에 실패 하였습니다. 다시 시도해 주십시오.'],
  ['3001', '[KMOTION]거래키 발급에 실패하였습니다. 다시 시도해 주십시오.'],
  ['3002', '[KMOTION]인증 데이터 처리에 실패하였습니다. 다시 시도해 주십시오.'],
  ['9101', '[KMOTION]결제 코드 발급에 실패하였습니다. 다시 시도해 주십시오.'],
  ['9102', '[KMOTION]결제 코드 발급에 실패하였습니다. 다시 시도해 주십시오.'],
  ['9103', '[KMOTION]결제 코드 발급에 실패하였습니다. 다시 시도해 주십시오.'],
  ['9104', '[KMOTION]결제 코드 발급에 실패하였습니다. 다시 시도해 주십시오.'],
  ['9105', '[KMOTION]결제 코드 발급에 실패하였습니다. 다시 시도해 주십시오.'],
  ['9106', '[KMOTION]결제 코드 발급에 실패하였습니다. 다시 시도해 주십시오.'],
  ['9199', '[KMOTION]거래 코드 발급에 실패하였습니다. 다시 시도해 주십시오.'],
  ['9201', '[KMOTION]거래키 요청에 실패하였습니다. 다시 시도해 주십시오.'],
  ['9202', '[KMOTION]거래키 요청에 실패하였습니다. 다시 시도해 주십시오.'],
  ['9203', '[KMOTION]거래키 요청에 실패하였습니다. 다시 시도해 주십시오.'],
  ['9204', '[KMOTION]거래키 요청에 실패하였습니다. 다시 시도해 주십시오.'],
  ['9205', '[KMOTION]거래키 요청에 실패하였습니다. 다시 시도해 주십시오.'],
  ['9206', '[KMOTION]결제 인증 시간이 만료되었습니다. 다시 시도해 주십시오.'],
  ['9207', '[KMOTION]이미 결제된 코드입니다. 다시 시도해 주십시오.'],
  ['9299', '[KMOTION]거래키 요청에 실패하였습니다. 다시 시도해 주십시오.'],
  ['9999', '[KMOTION]결제 도중 오류가 발생하였습니다. 다시 시도해 주십시오.'],
  // 이지페이 Client 오류
  ['M101', '통신 초기화 오류'],
  ['M102', 'PG 서버 접속 오류'],
  ['M103', 'PG 서버 DATA 전송 오류'],
  ['M104', 'PG 서버 DATA 수신 오류'],
  ['M201', '요청 전문 생성 오류'],
  ['M202', '요청 전문 암호화 오류'],
  ['M203', '응답 전문 복호화 오류'],
  ['M204', '전문 암복호화 정보 생성 오류'],
  ['M205', '추적번호 없음'],
  ['M206', '암호화 KEY정보 누락']
]);
