import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertNoticeService {

  constructor(
    private alertController: AlertController
  ) { }

  async noticeAlert(msg: string, msg2: string = null, timeout?: number) {
    const alert = await this.alertController.create({
      // header: 'Alert',
      subHeader: msg2,
      message: msg,
      buttons: ['확인']
    });

    await alert.present();

    if (timeout !== undefined) {
      setTimeout(() => {
        alert.dismiss();
      }, timeout);
    }
  }

  async noticeAlertConfirm(msg: string, okay: () => void, cancel: boolean, timeout?: number) {
    const options = {
      message: msg,
      backdropDismiss: cancel,
      buttons: cancel ? [
        {
          text: '취소',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: '확인',
          handler: okay
        }
      ] : [
        {
          text: '확인',
          handler: okay
        }
      ]
    };

    const alert = await this.alertController.create(options);

    await alert.present();

    if (timeout !== undefined) {
      setTimeout(() => {
        alert.dismiss();
      }, timeout);
    }
  }

  async blockNoticeAlertConfirm(msg: string, okay: () => void, timeout?: number) {
    const alert = await this.alertController.create({
      message: msg,
      backdropDismiss: false,
      buttons: [
        {
          text: '확인',
          handler: okay
        }
      ]
    });

    await alert.present();
    
    if (timeout !== undefined) {
      setTimeout(() => {
        alert.dismiss();
      }, timeout);
    }
  }
}
